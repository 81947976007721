import React from 'react'
import cancelable from 'react-disposable-decorator'
import { get } from 'lodash'
import QueryString from 'query-string'
// sofe imports
import { CprButton, CprLoader } from 'canopy-styleguide!sofe'
// project imports
import { checkActivateToken } from './activate-account.resource.js'
import ActivateAccountForm from './activate-account-form/activate-account-form.component.js'
import { doesTokenLookValid } from '../utilities/checkToken.js'
import { Scoped, always, maybe } from 'kremling';

@cancelable
export default class ActivateAccount extends React.PureComponent {

  state = {
    invalidToken: false,
    tokenAlreadyUsed: false,
    loading: true,
    firstName: '',
    lastName: '',
    email: '',
  }

  componentDidMount() {
    this.checkTokenAndUpdateComponent()
  }

  componentDidUpdate() {
    this.state.loading && this.checkTokenAndUpdateComponent()
  }

  render () {
    return (
      this.getLoadingOrContent()
    )
  }

  getLoadingOrContent = () => {
    if (this.state.loading) {
      return (<CprLoader page={true} />)
    } else if (this.state.invalidToken) {
      return this.invalidToken()
    } else {
      return this.getActivateForm()
    }
  }

  checkTokenAndUpdateComponent = () => {
    this.activateAccountToken = get(this.props, 'match.params.activate_account_token')
    const parsed = QueryString.parse(this.props.location.search)
    this.redirectUrl = parsed.redirect_url
    const softTokenCheck = doesTokenLookValid(this.activateAccountToken)
    if (softTokenCheck.maybeValid) {
      this.props.cancelWhenUnmounted(
        checkActivateToken(this.activateAccountToken).subscribe(
          response => {
            if (response.show_first_time_login) {
              this.props.setOnboarding(true)
            }
            this.setState({
              loading: false,
              invalidToken: false,
              email: softTokenCheck.email,
              firstName: softTokenCheck.firstName,
              lastName: softTokenCheck.lastName,
              tenantId: softTokenCheck.tenantId
            })
          },
          (e) => {
            const newState = {loading: false, invalidToken: true}
            if (e.status === 410) {
              newState.tokenAlreadyUsed = true
            }
            this.setState(newState)
          }
        )
      )
    } else {
      this.setTokenInvalid()
    }
  }

  setTokenInvalid = () => {
    this.setState(prevState => {
      if (!prevState.invalidToken || prevState.loading) {
        return {invalidToken: true, loading: false}
      }
    })
  }

  invalidToken = () => {
    return (
      <div className={`cps-subheader-sm cps-color-primary cps-wt-bold`}>
        {
          this.state.tokenAlreadyUsed ?
          'This account activation token has already been used and cannot be used again.' :
          'The account activation token is expired or invalid. Ask whoever invited you to resend the invitation.'
        }
        <div className={`cps-padding-bottom-16`} />
        <CprButton
          actionType={`primary`}
          type='button'
          onClick={this.routeToSignIn}
        >
          Return to sign in
        </CprButton>
      </div>
    )
  }

  routeToSignIn = () => {
    this.props.history.push('/login')
  }


  getActivateForm = () => {
    return (
      <Scoped css={css}>
        <div className={always('').maybe('flexed', this.props.footer)}>
          {!this.props.footer &&
            <div className={`${this.props.mobile ? 'message-header-mobile' : ''} cp-pb-32`} style={{textAlign: 'center'}}>
              {!this.props.mobile || !this.props.isWhiteLabelDomain && (<>
                Welcome to Canopy.<br />
              </>)}
              Please create your account.
            </div>
          }
          <ActivateAccountForm
            footer={this.props.footer}
            tenantStruct={this.props.tenant}
            setTokenInvalid={this.setTokenInvalid}
            redirectUrl={this.redirectUrl}
            history={this.props.history}
            token={this.activateAccountToken}
            email={this.state.email}
            firstName={this.state.firstName}
            lastName={this.state.lastName}
            tenantId={this.state.tenantId}
            isMobile={this.props.mobile}
          />
        </div>
      </Scoped>
    )
  }
}


const css = `
& .flexed {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

& .message-header-mobile {
  font-size: 1.4rem;
}
  `
