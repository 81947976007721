import React from 'react'
import { Scoped } from 'kremling';

export default class MobileAppDownload extends React.Component {

  render () {
    return (
      <Scoped css={css}>
        <div className={`${this.props.mobile ? 'cp-pt-20' : 'appDownload'}`}>
          <div className="cps-subheader-sm">Try our new mobile app</div>
          <div className="imageContainer">
            <a href='https://apps.apple.com/us/app/canopy/id1477121348'>
              <img className="appStore" alt='Download on the App Store' src='https://cdn.canopytax.com/static/global-settings/mobile/ios-store.png'></img>
            </a>
            <a href='https://play.google.com/store/apps/details?id=com.canopytax.client&hl=en_US'>
              <img alt='Get it on Google Play' src='https://cdn.canopytax.com/static/global-settings/mobile/android-store.png'></img>
            </a>
          </div>
          <div className="cps-body">
            <a href="https://support.getcanopy.com/hc/en-us/articles/9127185749915-Log-in-to-the-Client-Portal-Mobile-App" target="_blank" rel="noreferrer noopener">
              Need help getting started?
            </a>
          </div>
        </div>
      </Scoped>
    )
  }
}

const css = `
& .appDownload {
    padding-top: 90px;
  }

& .imageContainer {
  display: flex;
  align-items: center;
  margin-top: 8px;
  margin-bottom: 8px;
}

& .appStore {
  margin-right: 1.2rem;
}

`;
