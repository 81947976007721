import { fetchAsObservable } from 'fetcher!sofe'

export function login({grantType = 'password', redirectUrl, email, password, canopyPassword}) {
  if (!email) {
    throw new Error('email is required')
  }
  if (!password) {
    throw new Error('password is required')
  }

  const body = {
    grant_type: grantType,
    redirect_url: redirectUrl,
    email,
    password,
  };

  if (canopyPassword) {
    body.canopy_password = btoa(canopyPassword);
  }

  return fetchAsObservable(`/token`, {
    passThrough401: true,
    method: 'post',
    headers: {
      'Authorization': 'Basic ' + btoa('TaxUI:f7fsf29adsy9fg')
    },
    body,
  })
}
