import React from 'react'
import { CprLoader } from 'canopy-styleguide!sofe'
import { actuallyLogoutOfApp } from './logout.helper.js'

export default class LogoutComponent extends React.PureComponent {
  componentDidMount () {
    actuallyLogoutOfApp(this.props.location.search)
  }

  render () {
    return (
      <CprLoader page />
    )
  }
}
