import { fetchAsObservable } from 'fetcher!sofe'

export function sendCode(uuid, methodId) {
  return fetchAsObservable(`/wg/mfa-send?uuid=${uuid}`, {
    method: 'post',
    body: {
      uuid,
      method: methodId,
    },
    passThrough401: true
  })
}

export function getMfaTypes(uuid) {
  return fetchAsObservable(`/wg/mfa-methods-creds?uuid=${uuid}`)
}
