import { from } from 'rxjs'
import { flatMap } from 'rxjs/operators'
import { logout, mobileLogout } from './logout.resource.js'
import { catchAsyncStacktrace } from 'auto-trace';

export function logoutOfApp(redirectUrl) {
  const originStr = ensurePageReload(window.location.origin);
  const typeStr = window.appIsMobile ? 'm' : '#';
  const redirectUrlStr = redirectUrl ? `?redirect_url=${encodeURIComponent(redirectUrl)}` : '';

  const logoutUrl = `${originStr}/${typeStr}/logout${redirectUrlStr}`;
  window.location.assign(logoutUrl);
  return logoutUrl;
}

export function actuallyLogoutOfApp(urlQueryParams) {
  const originStr = ensurePageReload(window.location.origin);
  const typeStr = window.appIsMobile ? 'm' : '#'
  const urlQueryParamsStr = urlQueryParams || '';

  const loginUrl = `${originStr}/${typeStr}/login${urlQueryParamsStr}`;

  const logoutObs = window.appIsMobile ? mobileLogout : importCanopyUrlsAndLogout
  clearUserDataFromLocalStorage()
  logoutObs().subscribe(
    () => window.location.assign(loginUrl),
    catchAsyncStacktrace()
  )

  return loginUrl;
}

function importCanopyUrlsAndLogout() {
  return from(SystemJS.import('canopy-urls!sofe')).pipe(
    flatMap(canopyUrls => from(canopyUrls.default.bootstrap())),
    flatMap(logout)
  )
}

function clearUserDataFromLocalStorage() {
  const localStorageToKeep = [
    'login-page:canopy-password',
    'sofe',
    'cp:sofe-inspector',
    'feature:',
    'canopy-squad',
    'cp-admin-advanced-search-toggle',
    'saved-time-filter',
    'optout',
    'optin',
    '-bubbleList',
    '__ugApiEventsPushed',  // userguiding
    '___ug___',             // userguiding
    '__UGS__uid',           // userguiding
  ]
  for (let i in window.localStorage) {
    if (!localStorageToKeep.some(keep => i.includes(keep))) { // not a reserved item
      localStorage.removeItem(i)
    }
  }
}

export function ensurePageReload(url) {
  // We want to force a page reload on sign out because not all child applications unmount everything correctly
  // and they don't handle the global user and tenant changing.
  // For example, modals created by one child app but that are rendered by a different sofe service.
  // The only way I know of to force a reload when navigating to the same domain is to change the
  // protocol in the url. Since the load balancer auto-redirects from http -> https, this works.
  if (url.startsWith('https')) {
    return 'http' + url.slice('https'.length);
  } else {
    return url;
  }
}
