import React from 'react'
import cancelable from 'react-disposable-decorator'
import { get, merge } from 'lodash'
import { catchAsyncStacktrace, catchSyncStacktrace, asyncStacktrace } from 'auto-trace'
import { from } from 'rxjs'
// sofe imports
import { CprButton, CprLoader } from 'canopy-styleguide!sofe'
import Auth from 'cp-client-auth!sofe'
// Project Imports
import { updateEmail } from './change-email.resource.js'
import LoginComponent from '../login/login.component.js'
import { doesTokenLookValid } from '../utilities/checkToken.js'

@cancelable
export default class ChangeEmail extends React.PureComponent {

  state = {
    disabled: true,
    validToken: true,
    loadingUser: true,
    updatingEmail: true,
    user: undefined,
    email: '',
    invalidTokenStatus: undefined
  }

  componentDidMount () {
    this.mounted = true
    const changeEmailToken = get(this.props, 'match.params.change_email_token')

    this.props.cancelWhenUnmounted(
      from(Auth.checkLoginStatus())
      .subscribe(
        result => this.setState({loadingUser: false, user: result.isLoggedIn}),
        asyncStacktrace(err => {
          this.setState({loadingUser: false});
          catchSyncStacktrace(err);
        })
      )
    );

    const softTokenCheck = doesTokenLookValid(changeEmailToken)

    if (softTokenCheck.maybeValid) {
      this.props.cancelWhenUnmounted(
        updateEmail(changeEmailToken).subscribe(
          () => { this.setState({validToken: true, email: softTokenCheck.newEmail || softTokenCheck.email, updatingEmail: false})},
          (e) => {
            this.setState({validToken: false, invalidTokenStatus: e.status, updatingEmail: false})
          }
        )
      )
    } else {
      this.setState({validToken: false, updatingEmail: false})
    }
  }

  componentWillUnmount () {
    this.mounted = false
  }

  render () {
    return this.state.updatingEmail ? <CprLoader page={true} /> : this.updateEmailFinished()
  }

  updateEmailFinished = () => {
    return this.state.validToken ? this.emailUpdatedSuccessfully() : this.badToken()
  }

  emailUpdatedSuccessfully = () => {
    if (this.state.loadingUser) {
      return <CprLoader page={true} />
    } else if (this.state.user === true) {
      return this.continueWorkingInCanopy();
    } else {
      return this.customLoginComponent();
    }
  }

  continueWorkingInCanopy = () => {
    return (
      <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
        Your email has been updated to <span className="cps-wt-bold cps-color-primary">{this.state.email}</span>.
        <a href="#/">
          <CprButton actionType="primary">
            Continue to Canopy
          </CprButton>
        </a>
      </div>
    );
  }

  customLoginComponent = () => {
    return (
      <div>
        <LoginComponent
          hideEmail={true}
          emailAddress={this.state.email}
          buttonText={'Verify'}
          showForgotPassword={true}
          {...this.props}
        >
        Please enter your Canopy password to verify your email change.
        </LoginComponent>
      </div>
    )
  }

  badToken = () => {
    return (
      <div>
        <span className={`cps-subheader-sm`}>
          {
            this.state.invalidTokenStatus === 410 ?
            'It looks like your change email token has already been used. Please request a new link.' :
            'The change email token is expired or invalid. Please try again or request a new link.'
          }
        </span>
        <div className={`cps-padding-bottom-16`} />
        <a href="#/login">
          <CprButton
            actionType={`primary`}
            type='button'
          >
            Return to sign in
          </CprButton>
        </a>
      </div>
    )
  }
}

export class CallbackAfterRender extends React.PureComponent {

  componentDidMount () {
    if (this.props.fn) {
      this.props.fn()
    }
  }

  render () {
    return null
  }
}
