import { useEffect, useState } from 'react';
import {fetchAsObservable} from 'fetcher!sofe';
import {pluck, tap} from 'rxjs/operators'
import {from, of} from 'rxjs'
import urls from 'canopy-urls!sofe';
import Auth from 'cp-client-auth!sofe'

export function getTenant() {
  return from(Auth.refetchTenant())
}

export function patchUser(userId, userPatch) {
  const canPatch = !!Object.keys(userPatch).length;

  if (canPatch) {
    const redirect = !!userPatch.password;
    return fetchAsObservable(`${urls.getAPIUrl()}/users/${userId}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        users: userPatch
      }),
    }).pipe(
      tap(() => {
        if (redirect) {
          window.location.href = `${urls.getAuthUrl()}/#/logout`
        }
      }),
      pluck('users')
    )
  } else {
    return of(null);
  }
}

export function patchTenant(tenantId, tenantPatch) {
  const canPatch = !!Object.keys(tenantPatch).length;

  if (canPatch) {
    return fetchAsObservable(`${urls.getAPIUrl()}/tenants/${tenantId}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json'
      },
      body: {
        tenants: tenantPatch,
      },
    }).pipe(
      pluck('tenants')
    )
  } else {
    return of(null);
  }
}

export function getAccounts(includeShared = true) {
  return fetchAsObservable(`/email-accounts?includeShared=${includeShared}`).pipe(pluck('email-accounts'))
}

export function getUser() {
  return from(Auth.refetchLoggedInUser())
}

export function checkEmailAvailable(emails) {
  if (!emails.length) {
    throw Error(`Cannot check email availability without an email address.'`)
  }

  return fetchAsObservable(`/available-emails/`, {
    method: 'POST',
    body: {
      "available-emails": emails
    }
  })
}

export const postBatchUsers = userList => fetchAsObservable(`${urls.getAPIUrl()}/users`, {
    method: 'POST',
    body: {
      "users": userList
    }
  })

export const postAnalytic = (payload, event) => fetchAsObservable( `${urls.getAPIUrl()}/products/practice_management/features/directory/events/${event}`,
  {
    method: 'POST',
    body: payload
  }
)

export function useRoleTemplates() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [roleTemplates, setRoleTemplates] = useState([]);

  useEffect(() => {
    let obs = fetchAsObservable(
      `${urls.getWorkflowUrl()}/wg/role-templates`
    ).subscribe(response => {
        setRoleTemplates(response['role-templates']);
        setLoading(false);
        setError(null);
      }, error => {
        setRoleTemplates([]);
        setLoading(false);
        setError(error);
      });

    return () => {
      obs.unsubscribe();
    };
  }, []);

  return {
    loading,
    error,
    roleTemplates,
  };
}
