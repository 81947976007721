import React from 'react'
import { CpButton,  } from 'canopy-styleguide!sofe'
import { k, useCss } from 'kremling';

export function MobileMfaVerified({redirectUrl}){
  const scope = useCss(css)

  return (
    <>
      <div className="cps-text-center">
        <img
          src='https://cdn.canopytax.com/static/login-ui/verification-email.svg'
          alt="Verification Email"
        />
        <div className={`cps-title cps-wt-semibold cp-mv-32`}>
          Account verified!
        </div>
      </div>
      <div className={`cps-padding-bottom-16`} {...scope}>
        <CpButton
          className='mobile-button'
          onClick={ () => { window.location.href = redirectUrl } }
          disableOnClick={ true }
        >
          Back to Canopy
        </CpButton>
      </div>
    </>
  )
}

const css = k`
  .mobile-button {
    width: 100%;
    height: 4.8rem!important;
  }
`
