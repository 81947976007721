import React, {useState, useEffect, useMemo, useCallback} from 'react'
import { MobileDesktopWrapper } from 'src/mobile/mobile-wrapper/mobile-wrapper.component.js'
import { MfaType } from './mfa-type.component'
import { MfaTimeout } from './mfa-timeout.component'
import { SubmitMfa } from './submit-mfa.component'
import { sendCode, getMfaTypes } from './code-mfa.resource'
import { MobileMfaVerified } from './mobile-mfa-verified.component'
import { asyncStacktrace, catchSyncStacktrace } from 'auto-trace';
import QueryString from 'query-string'
import CheckAuthCookieAndRefresh from 'src/login/check-auth-cookie-and-refresh/check-auth-cookie-and-refresh.component.js'
import { throttle } from 'lodash'
import {useWhiteLabelSettings} from 'src/white-label-settings/white-label-settings.hook.js'
import { a, m, useCss, k } from 'kremling'

export default function CodeMfa(props) {
  const { location, mobile } = props
  const [ mfaTypes, setMfaTypes ] = useState(window.history.state ? window.history.state.mfaTypes : location?.state?.mfaTypes)
  const [mfaType, setMfaType] = useState(mfaTypes?.[0] || {})
  const [typeConfirmed, setTypeConfirmed] = useState(false)
  const [mobileVerified, setMobileVerified] = useState(false)
  const [mfaTimeout, setMfaTimeout] = useState(false)
  const [codeSent, setCodeSent] = useState(false)
  const [resendCode, setResendCode] = useState(0)
  const [bannerText, setBannerText] = useState('Two-Factor Authentication')
  const [isWhiteLabelDomain, whiteLabelSettings] = useWhiteLabelSettings()
  const scope = useCss(css)

  const uuid = useMemo(() => {
    // uuid will be passed as a query param if logging in to client portal via main canopy login.
    return (window.history.state ? window.history.state.uuid : location?.state?.uuid) || QueryString.parse(location.search).uuid
  }, [location])

  useEffect(() => {
    // If logging in to client portal from main canopy login, mfaTypes will be undefined and we must fetch them
    if (uuid && !mfaTypes) {
      getMfaTypes(uuid).subscribe(
        response => {
          setMfaTypes(response.mfa_methods)
          setMfaType(response.mfa_methods?.[0])
        },
        catchSyncStacktrace
      )
    }
  }, [uuid, mfaTypes])

  const redirectUrl = useMemo(() => {
    const parsed = QueryString.parse(location.search)
    return parsed.redirect_url
  }, [location])

  useEffect(() => {
    if (typeConfirmed) {
      const subscription = sendCode(uuid, mfaType.id).subscribe(
        resp => {
          setCodeSent(true)
        },
        asyncStacktrace(err => {
          if (err.status === 401) {
            invalidateSession()
          } else {
            catchSyncStacktrace(err)
          }
        })
      )
      setBannerText('Enter One-Time Passcode')
      return () => subscription.unsubscribe()
    } else {
      setBannerText('Two-Factor Authentication')
    }
  }, [typeConfirmed, resendCode])

  const invalidateSession = useCallback(() => {
    setBannerText('Authentication Timeout')
    setMfaTimeout(true)
  }, [])

  const routeToSignIn = () => {
    setBannerText(null)
    props.history.push({pathname: '/login', search: location.search})
  }

  const postLoginRedirect = (multitenant, user) => {
    if (multitenant) {
      return props.history.push({pathname: '/select-user', search: location.search})
    }
    if (redirectUrl && (redirectUrl !== window.location.origin)) {
      if (mobile && window.history.state.authCodeFlow) {
        setMobileVerified(true);
      } else {
        return window.location = redirectUrl
      }
    } else {
      const baseRoute = user?.role === 'Client' ? '/m/' : '/'
      window.location = baseRoute
    }
  }

  const resend = useCallback(throttle(() => setResendCode((val) => val + 1), 5000), [])

  const renderTimeout = () => {
    return (
      <>
        <div
          {...scope}
          className={a('cp-title cp-mb-24 cp-wt-semibold')
            .m('cp-text-left', !whiteLabelSettings)
            .m('cp-mt-24 mobile-header', mobile)}
        >
          {bannerText}
        </div>
        <MfaTimeout
          routeToSignIn={routeToSignIn}
        />
      </>
    )
  }
  if (mfaTimeout) {
    if (mobile) {
      return (
        <MobileDesktopWrapper mobile={mobile} whiteLabelSettings={whiteLabelSettings}>
          {renderTimeout()}
        </MobileDesktopWrapper>
      )
    } else {
      return renderTimeout()
    }

  }

  return (
    <MobileDesktopWrapper mobile={mobile} whiteLabelSettings={whiteLabelSettings}>
      <div
        {...scope}
        className={a('cp-title cp-mb-24 cp-wt-semibold')
        .m('cp-text-left', !whiteLabelSettings)
        .m('cp-mt-24 mobile-header', mobile)}
      >
        {bannerText}
      </div>
      <div {...scope} className={m('cp-text-left mobile-mfa-wrapper', mobile)}>
        {(!typeConfirmed || (typeConfirmed && !codeSent)) && (
          <MfaType
            mfaType={mfaType}
            setMfaType={setMfaType}
            setTypeConfirmed={setTypeConfirmed}
            mfaTypes={mfaTypes}
            routeToSignIn={routeToSignIn}
            mobile={mobile}
          />
        )}
        {typeConfirmed && codeSent && !mobileVerified && (
          <SubmitMfa
            mfaType={mfaType}
            setTypeConfirmed={setTypeConfirmed}
            uuid={uuid}
            postLoginRedirect={postLoginRedirect}
            resend={resend}
            invalidateSession={invalidateSession}
            mobile={mobile}
          />
        )}
        {mobileVerified && (
          <MobileMfaVerified redirectUrl={redirectUrl} />
        )}
      </div>
      {isWhiteLabelDomain &&
        <div {...scope} className="help-text">
          Having troubles? Contact your practitioner.
        </div>
      }
      <CheckAuthCookieAndRefresh {...props} />
    </MobileDesktopWrapper>
  )
}

const css = k`
  .mobile-header {
    color: var(--cp-color-app-primary)
  }

  .mobile-mfa-wrapper .mobile-button-group .cp-button {
    height: 4.8rem;
    width: 100%;
  }

  .help-text {
    font-size: 1.2rem;
    color: var(--cp-color-app-secondary-text);
    text-align: left;
    margin-top: 1.6rem;
  }
`
