import React, {useState} from "react"
import { Scoped } from 'kremling'

export default function ModalContent({setDontBotherMe, setExit, cancel}) {
  const [checked, check] = useState(false)

  const proceed = () => checked
    ? setDontBotherMe(true)
    : setExit(true)

  return (
  <Scoped css={css}>
    <div className="cps-modal">
      <div className="cps-modal__screen"></div>
        <div className="cps-modal__dialog cps-card__height-3">
        <div className="cps-card__header cps-subheader-sm"><span>Skip setup</span>
        <a onClick={cancel} className="cps-modal__dialog__close cps-icon cps-icon-close"></a>
        </div>
        <div className="cps-card__body">
          <div className='copy'>
            Are you sure you want to skip setup? You will continue to get the setup until completed or choose to check the box below and finish these steps in Settings.
          </div>
          <label className="cps-checkbox">
            <input
              type="checkbox"
              checked={checked}
              onChange={()=> check(!checked)}
            />
            <span>
              Don't show me this company setup again
            </span>
          </label>
        </div>
        <div className="breaker"/>
        <div className="cps-modal__dialog__actions">
          <button onClick={proceed} className="cps-btn +primary">Skip and go to Canopy</button><a onClick={cancel} className="cps-link">Cancel</a>
        </div>
      </div>
    </div>
  </Scoped>
  )
}

const css = `
  & .copy {
    margin-bottom: 16px;
  }
  & .breaker {
    border-top: var(--cps-color-athens) solid 1px;
  }
`