import React, { Component } from 'react';
import { node, object } from 'prop-types';
import { Scoped, a } from 'kremling';
import LoginGreeting from './login-greeting.component';
import { overlayOpacity }  from './overlay.helper.js';

export default class Layout2 extends Component {
  static propTypes = {
    children: node,
    settings: object,
  }

  render() {
    const { children, settings } = this.props;
    const showWelcomeMessage = settings.show_welcome_message !== undefined ? settings.show_welcome_message : true;

    return (
      <Scoped css={css}>
        <div
          className="layout"
          style={{backgroundImage: `url(${settings.login_photo_url})`}}
        >
          <div className="layout__overlay" style={{
            backgroundColor: settings.color_hex,
            opacity: overlayOpacity(showWelcomeMessage, settings),
          }} />
          <div className="layout__content">
            <div className="message">
              { showWelcomeMessage &&
                <LoginGreeting
                  loginWelcomeTitle={settings.login_welcome_title}
                  loginWelcomeDescription={settings.login_welcome_description}
                  loginCompanyName={settings.company_name}
                />
              }
            </div>
            <div className={`${a('form').m('form-no-logo', !settings.logo_url).t("formWithDownload", "formWithoutDownload", !this.props.isWhiteLabelDomain)}`}>
              {this.props.settings.logo_url && <img src={settings.logo_url} alt={settings.company_name || "Portal Logo"} className="header-logo-img"/>}
              {children}
            </div>
          </div>
        </div>
      </Scoped>
    );
  }
}

const css = `
  & .layout {
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    height: 100%;
    width: 100%;
    padding-bottom: 8%;
  }

  & .header-logo-img {
    min-width: 156px;
    max-width: 320px;
    min-height: 56px;
    max-height: 64px;
    object-fit: contain;
    object-position: 0 0;
    margin-bottom: 72px;
  }

  & .layout__overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
  }

  & .layout__content {
    position: relative;
    display: flex;
    height: 100%;
    width: 100%;
    max-width: 1030px;
    margin: 0 auto;
    align-items: center;
    z-index: 1;
  }

  & .message {
    flex-grow: 1;
    padding-right: 128px;
    padding-bottom: 0;
  }

  & .form {
    width: 435px;
    background-color: #fff;
    border-radius: 8px;
    flex-grow: 0;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    min-height: 640px;
    box-shadow: 0 10px 40px -24px #393b3f;
  }

  & .form.form-no-logo {
    padding-top: 30px;
    justify-content: center;
  }

  & .form.formWithDownload {
    padding: 90px 50px 20px;
  }

  & .form.formWithoutDownload {
    padding: 90px 50px 50px;
  }
`;
