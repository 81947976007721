
export function overlayOpacity(showWelcomeMessage, settings){
  if (settings.login_photo_url && showWelcomeMessage) {
    return .7
  } else if (settings.login_photo_url && !showWelcomeMessage) {
    return 0
  } else {
    return 1
  }
}
