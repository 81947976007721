import React from 'react'
import cancelable from 'react-disposable-decorator'
import { catchAsyncStacktrace } from 'auto-trace'
import * as EmailValidator from 'email-validator'
import QueryString from 'query-string'
import {track} from 'cp-analytics';
// project
import IconInput from '../icon-input/icon-input.component.js'
import FlexibleButton from '../flexible-button/flexible-button.component.js'
import FlexibleButtonBlock from '../flexible-button-block/flexible-button-block.component.js'
// sofe imports
import { postResetPassword } from './forgot-password.resource.js'
import styles from './forgot-password.styles.css'
import {successToast} from 'toast-service!sofe';

@cancelable
export default class ForgotPassword extends React.PureComponent {

  state = {
    email: '',
    isRobot: true,
    passwordWasReset: false,
    invalidEmail: true,
    showEmailErrors: false,
  }

  componentDidMount () {
    track('practice_management', 'directory', 'login_forgot_password');
    this.mounted = true
    window.successfulGoogleRecaptcha = () => {
      if (this.mounted) {
        this.setState({isRobot: false})
      }
    }
    this.initializeRecaptchaCallback()
    this.maybeSetRecaptchaScriptOnDom()
  }

  render () {
    return (
      <div>
        {this.renderContent()}
      </div>
    )
  }

  renderContent = () => {
    if (this.props.mobile) {
      return this.forgotPasswordForm();
    } else {
      return this.state.passwordWasReset ? this.checkYourInbox() : this.forgotPasswordForm();
    }
  }

  componentWillUnmount () {
    this.mounted = false
    delete window.successfulGoogleRecaptcha
    delete window.grecaptchaOnloadCallback    
  }

  checkYourInbox = () => {
    return (
      <div>
        <div className='cps-padding-bottom-8' style={{ fontSize: '18px' }}>
          Check your inbox!
        </div>
        <div className='cps-padding-bottom-32'>
          We've emailed you some instructions on how to reset your password.
        </div>
        <FlexibleButton
          btnType='primary'
          type='button'
          onClick={this.routeToSignIn}
        >
          Return to sign in
        </FlexibleButton>
      </div>
    )
  }

  forgotPasswordForm = () => {
    const isDisabled = this.state.invalidEmail || this.state.isRobot
    return (
      <div id='forgot-password-form'>
        {!this.props.mobile && (
          <div className='cps-title cps-wt-semibold cps-padding-bottom-8'>
            Forgot your password?
          </div>
        )}
        <div className={`cps-padding-bottom-24 ${this.props.mobile ? 'cps-text-center' : ''}`}>
          To reset your password, enter your email address.
        </div>
        <form onSubmit={this.submitForgotPassword}>
          <div className={`cps-form-group cps-margin-bottom-32 ${this.state.invalidEmail && this.state.showEmailErrors && 'cps-has-error'}`}>
            <label>Email Address</label>
            <IconInput
              autoFocus
              leftIcon={null}
              value={this.state.email}
              type='email'
              className='cps-form-control'
              placeholder={'Enter your email address'}
              aria-label='Email'
              onChange={this.updateEmail}
              onBlur={this.emailBlur}
            />
            <span className='cps-error-block' style={{position: 'absolute'}} aria-hidden={`${!(this.state.showEmailErrors && this.state.invalidEmail)}`}>
              Please enter a valid email address
            </span>
          </div>
          <div className={`cps-form-group cps-margin-bottom-32 ${this.props.mobile ? styles.mobileCaptcha : ''}`}>
            <div
              ref={el => this.gCaptchaEl = el}
            />
          </div>
          <FlexibleButtonBlock>
            <FlexibleButton
              btnType='primary'
              disabled={isDisabled}
            >
              Reset password
            </FlexibleButton>
            <FlexibleButton
              btnType='flat'
              type='button'
              onClick={this.routeToSignIn}
            >
              Cancel
            </FlexibleButton>
          </FlexibleButtonBlock>
        </form>
      </div>
    )
  }

  submitForgotPassword = (e) => {
    e.preventDefault()
    const response = window.grecaptcha.getResponse(this.widgetId)
    const postBody = {
      email: this.state.email,
      'g-recaptcha-response': response
    }
    if (response) {
      this.props.cancelWhenUnmounted(
        postResetPassword(postBody).subscribe(
          () => {
            track('practice_management', 'directory', 'login_reset_password');

            this.setState({passwordWasReset: true})
            if (this.props.mobile) {
              this.createMobileEmailSuccessToast();
            }
          },
          catchAsyncStacktrace()
        )
      )
    }
  }

  createMobileEmailSuccessToast = () => {
    successToast(`We've emailed you some instructions on how to reset your password.`);
  }

  routeToSignIn = () => {
    const parsed = QueryString.parse(this.props.location.search)
    const redirectUrl = parsed.redirect_url
      ? `?redirect_url=${encodeURIComponent(parsed.redirect_url)}`
      : '';
    this.props.history.push(`/login${redirectUrl}`)
  }

  emailBlur = (e) => {
    this.setState({showEmailErrors: true})
  }

  updateEmail = (e) => {
    const newEmailValue = e.target.value
    const isEmailValid = EmailValidator.validate(newEmailValue)
    this.setState({email: newEmailValue, invalidEmail: !isEmailValid})
  }

  initializeRecaptchaCallback = () => {
    window.grecaptchaOnloadCallback = () => {
      this.widgetId = window.grecaptcha.render(
        this.gCaptchaEl,
        {
          'sitekey': '6LcXUBcTAAAAABv7ucsO-5OdmXfVkGK5OTiAu7Jz',
          'callback': 'successfulGoogleRecaptcha'
        }
      )
    }
  }

  maybeSetRecaptchaScriptOnDom = () => {
    if (window.grecaptcha) {
      window.grecaptchaOnloadCallback()
    } else {
      const script = document.createElement('script')
      script.setAttribute('src', 'https://www.google.com/recaptcha/api.js?onload=grecaptchaOnloadCallback&render=explicit')
      script.setAttribute('async', '')
      script.setAttribute('defer', '')
      document.head.appendChild(script)
    }
  }
}
