import React from 'react'
import { isEmpty, get } from 'lodash'
import cancelable from 'react-disposable-decorator'
import { asyncStacktrace, catchSyncStacktrace } from 'auto-trace'
// sofe imports
import { CprButton } from 'canopy-styleguide!sofe'
// project imports
import Password from '../../password/password.component.js'
import RequiredField from 'src/form/required-field.component.js'
import styles from './activate-account-form.styles.css'
import inputStyles from '../../icon-input/icon-input.styles.css'
import { activateAccount } from '../activate-account.resource.js'
import moment from 'moment-timezone/builds/moment-timezone-with-data-2012-2022.min.js'
import {getUser, patchUser} from './users.resource.js'
import { forkJoin, of as rxOf } from 'rxjs'
import {flatMap, map, catchError } from 'rxjs/operators'
import {getTenant} from 'src/onboarding/resources.js'

@cancelable
export default class ActivateAccountForm extends React.PureComponent {

  constructor(props) {
    super(props)

    this.state = {
      firstName: props.firstName || '',
      middleName: props.middleName || '',
      lastName: props.lastName || '',
      showFirstNameError: false,
      showLastNameError: false,
      isPasswordValid: false,
      tenantId: props.tenantId || '',
      agreed: props.isMobile ? true : false,
    }

    this.updateFirst = this.nameChange.bind(this, 'firstName')
    this.updateMiddle = this.nameChange.bind(this, 'middleName')
    this.updateLast = this.nameChange.bind(this, 'lastName')
  }

  render () {
    const isDisabled = !this.state.isPasswordValid || !this.areNamesValid() || !this.state.agreed

    return (
      this.props.isMobile ? (
        this.renderMobile(isDisabled)
      ) : (
        this.renderDesktop(isDisabled)
      )
    );
  }

  renderMobile = (isDisabled) => {
    return (
      <form onSubmit={this.submitAccountActivate} className={`${styles.mobileForm}`}>
        <div>
          <input
            value={this.props.email}
            type='text'
            className='cps-form-control'
            placeholder='Email'
            aria-label='Email'
            disabled={true}
          />
        </div>
        <div className={`${styles.mobileNameInputs}`}>
          <div className={`${styles.flexColumn}`}>
            <div
              className={`cps-form-group ${inputStyles.mobileInput} ${styles.flex1} ${this.state.showFirstNameError && 'cps-has-error'}`}
            >
              <div className="cps-margin-top-16">
                <RequiredField>First name</RequiredField>
              </div>
              <input
                id='activate-account-form-first-name-input'
                type='text'
                autoFocus
                aria-label='First name'
                placeholder='First name'
                value={this.state.firstName}
                onChange={this.updateFirst}
                onBlur={this.firstBlur}
                className={`cps-form-control ${styles.mobileInput}`}
              />
              {
                this.state.showFirstNameError &&
                  <span className={`cps-error-block ${styles.absolute}`}>First name is required</span>
              }
            </div>
            <div
              className={`cps-form-group ${inputStyles.mobileInput} ${styles.mobileInput} ${styles.flex1} ${this.state.showLastNameError && 'cps-has-error'}`}
            >
              <div className="cps-margin-top-8">
                <RequiredField>Last name</RequiredField>
              </div>
              <input
                id='activate-account-form-last-name-input'
                type='text'
                aria-label='Last name'
                placeholder='Last name'
                value={this.state.lastName}
                onChange={this.updateLast}
                onBlur={this.lastBlur}
                className={`cps-margin-left-16 cps-form-control ${styles.mobileInput}`}
              />
              {
                this.state.showLastNameError &&
                  <span className={`cps-error-block ${styles.absolute}`}>Last name is required</span>
              }
            </div>
          </div>
        </div>
        <Password
          ref={el => this.passwordEl = el}
          showErrors={this.state.showErrors}
          onInputChange={this.onPasswordChange}
          showConfirmPassword={true}
          showHelperText={true}
          showCanopyPassword={false}
          noTabIndex={true}
          showPasswordStrength={true}
          minPasswordLength={10}
          passwordLabel={<RequiredField>Create password</RequiredField>}
          confirmLabel={<RequiredField>Confirm password</RequiredField>}
        />
        <div className={`cps-padding-bottom-16 cps-caption cps-light-gray`}>
          <div>
            <span className={`${styles.right}`}>
              By continuing you agree to the <a href="https://www.canopytax.com/tos" target="_blank" rel="noopener noreferrer">Terms of Service</a> and <a href='https://www.canopytax.com/privacy' target="_blank" rel="noopener noreferrer">Privacy Policy</a>
            </span>
          </div>
        </div>

        <CprButton
          actionType='primary'
          type='submit'
          disabled={isDisabled}
          className={`${styles.mobileButton}`}
        >
          Agree and Create Account
        </CprButton>
      </form>
    )
  }

  renderDesktop = (isDisabled) => {
    const isOnboarding = this.props.footer !== undefined

    return (
      <>
        <form onSubmit={this.submitAccountActivate}>
        <div className="form-body">
          <div className={`${styles.nameInfo} cps-padding-bottom-8 name-label`}>
            {isOnboarding ?
              <RequiredField>Name</RequiredField> :
              <RequiredField>Full Name</RequiredField>
            }
          </div>
          <div className={`cps-margin-bottom-32`}>
            <div className={`${styles.flexBetween}`}>
              <div
                className={`cps-form-group cps-margin-bottom-0 ${styles.flexPercent} ${this.state.showFirstNameError && 'cps-has-error'}`}
              >
                <input
                  type='text'
                  autoFocus
                  aria-label='First Name'
                  placeholder='First'
                  value={this.state.firstName}
                  onChange={this.updateFirst}
                  onBlur={this.firstBlur}
                  className={`cps-form-control`}
                />
                {
                  this.state.showFirstNameError &&
                    <span className={`cps-error-block ${styles.absolute}`}>First name is required</span>
                }
              </div>
              <div
                className={`cps-form-group cps-margin-bottom-0 ${styles.middleName}`}
              >
                <input
                  type='text'
                  aria-label='Middle Name'
                  placeholder='MI'
                  value={this.state.middleName}
                  onChange={this.updateMiddle}
                  onBlur={this.middleBlur}
                  className={`cps-form-control`}
                />
              </div>
              <div
                className={`cps-form-group cps-margin-bottom-0 ${styles.flexPercent} ${this.state.showLastNameError && 'cps-has-error'}`}
              >
                <input
                  type='text'
                  aria-label='Last Name'
                  placeholder='Last'
                  value={this.state.lastName}
                  onChange={this.updateLast}
                  onBlur={this.lastBlur}
                  className={`cps-form-control`}
                />
                {
                  this.state.showLastNameError &&
                    <span className={`cps-error-block ${styles.absolute}`}>Last name is required</span>
                }
              </div>
            </div>
          </div>
          <div className={`cps-margin-bottom-32`}>
            <div className="cps-margin-bottom-8">
              <RequiredField>Email address</RequiredField>
            </div>
            <input
              value={this.props.email}
              type='text'
              className='cps-form-control'
              placeholder='Email'
              aria-label='Email'
              disabled={true}
            />
          </div>
          {/* The default minPasswordLength is 8 for grandfathered passwords, but new passwords need to be 10 characters or greater */}
          <Password
            ref={el => this.passwordEl = el}
            showErrors={this.state.showErrors}
            onInputChange={this.onPasswordChange}
            showConfirmPassword={true}
            showHelperText={true}
            showCanopyPassword={false}
            noTabIndex={true}
            showPasswordStrength={true}
            minPasswordLength={10}
            passwordLabel={<RequiredField>Create password</RequiredField>}
            confirmLabel={<RequiredField>Confirm password</RequiredField>}
          />
          <div className={`cps-padding-bottom-16 cps-caption cps-light-gray`}>
            <div className="terms-and-service">
              <label className="cps-checkbox">
                <input type="checkbox" onChange={this.agreeCheck} checked={this.state.agreed}/>
                <span className={`${styles.right}`}>
                  By clicking, I agree to the <a href="https://www.canopytax.com/tos" target='_blank' rel='noopener noreferrer'>Terms of Service</a> and <a href='https://www.canopytax.com/privacy' target='_blank' rel='noopener noreferrer'>Privacy Policy</a>
                </span>
              </label>
            </div>
          </div>
      </div>
        {!isOnboarding &&
          <CprButton
            actionType='primary'
            type='submit'
            disabled={isDisabled}
            className={`${styles.space}`}
          >
            Sign in
          </CprButton>}
          {isOnboarding && this.props.footer(this.submitAccountActivate, isDisabled)}
        </form>
      </>
    )
  }

  agreeCheck = () => {
    this.setState({ agreed: !this.state.agreed });
  }

  submitAccountActivate = (e) => {
    const isOnboarding = this.props.footer !== undefined

    e && e.preventDefault()
    //be double sure that everything is valid
    if(this.areNamesValid() && this.state.isPasswordValid) {
      this.props.cancelWhenUnmounted(
        activateAccount(this.props.token, this.getSubmissionData())
        .pipe(
          flatMap(results => {
            // Update the user's timezone to be the system clock timezone
            return getUser(0)
              .pipe(
                flatMap(loggedInUser => patchUser(loggedInUser.id, {time_zone: moment.tz.guess()})),
                map(() => results),
                catchError(err => {
                  catchSyncStacktrace(err)
                  // If updating the user's timezone fails, don't prevent the user from finishing activation
                  return results
                }),
                flatMap(results =>
                  forkJoin(
                    rxOf(results),
                    getTenant()
                  )
                )
              )
          })
        )
        .subscribe(
          ([results, tenantStruct]) => {
            if (results.instance_url) {
              if(isOnboarding) {
                this.props.history.push('/login/activate-account/add-calendar')
              } else {
                window.location = results.instance_url
              }
            } else {
              this.props.history.push('/')
            }
          },
          asyncStacktrace(err => {
            const status = err.status
            if (status === 401) {
              this.passwordEl.resetPassword()
              if (this.props.setTokenInvalid) {
                this.props.setTokenInvalid()
              }
            } else {
              catchSyncStacktrace(err)
            }
          })
        )
      )
    }
  }

  onPasswordChange = () => {
    const isPasswordValid = this.passwordEl.getIsPasswordAndConfirmValid()
    this.setState({isPasswordValid})
  }

  areNamesValid = () => {
    const {firstName, lastName} = this.state
    return !isEmpty(firstName.trim()) && !isEmpty(lastName.trim())
  }

  nameChange = (key, e) => {
    const value = e.target.value
    this.setState({[key]: value})
  }

  firstBlur = () => {
    const invalidFirstName = isEmpty(this.state.firstName.trim())
    this.setState({showFirstNameError: invalidFirstName})
  }

  middleBlur = () => {
    const invalidMiddleName = isEmpty(this.state.middleName.trim())
    this.setState({showMiddleNameError: invalidMiddleName})
  }

  lastBlur = () => {
    const invalidLastName = isEmpty(this.state.lastName.trim())
    this.setState({showLastNameError: invalidLastName})
  }

  getSubmissionData = () => {
    const {firstName, lastName} = this.state
    const encodedPassword = this.passwordEl.getEncodedPassword()
    return {
      first_name: firstName,
      last_name: lastName,
      password: encodedPassword
    }
  }
}
