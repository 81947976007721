import { useWithUserAndTenant } from 'cp-client-auth!sofe'
import { useEffect, useState } from 'react'
import { catchAsyncStacktrace } from 'auto-trace'

import { postAnalytic } from 'src/onboarding/resources.js'

export const EXITED = 'ftl_page_aborted'
export const SKIPPED = 'ftl_page_skipped'
export const COMPLETED = 'ftl_completed'

export function useUnloadEvent(payload, event){
    const [user, tenant] = useWithUserAndTenant()
    useEffect(() => {
      let subscription$
      const subscribeAnalytic = () => {
        subscription$ = postAnalytic(
          {
            data:payload,
            event_date: Date.now(),
            tenant_id: tenant.id,
            user_id: user.id
          },
          event
        )
        .subscribe(_ => {},
         catchAsyncStacktrace()
        )
      }

      window.addEventListener(
        'beforeunload',
        subscribeAnalytic
      )

      return () => {
        window.removeEventListener(
          'beforeunload',
          subscribeAnalytic
        )
        subscription$ && subscription$.unsubscribe()
      }
    }, [payload, event])
}

export function useSkipEvent(skip, payload, event){
  const [user, tenant] = useWithUserAndTenant()
  const [status, setStatus] = useState(false)

  let subscription$
  useEffect(() => {
    const canProceed = skip && user && tenant
    if (canProceed) {
      subscription$ = postAnalytic(
        {
          data: payload,
          event_date: Date.now(),
          tenant_id: tenant.id,
          user_id: user.id
        },
        event
        )
        .subscribe(_ => {
          setStatus(true)
        },
        catchAsyncStacktrace()
      )
    }
    return () => subscription$ && subscription$.unsubscribe()
  }, [skip, user, tenant, payload, event])

 return status
}