import React from 'react';

const hours = 8;
const minutesPerHour = 60;
const secondsPerMinute = 60;
const millisecondsPerSecond = 1000;

const TIME_TO_WAIT_BEFORE_RELOAD = hours * minutesPerHour * secondsPerMinute * millisecondsPerSecond;

export default class ReloadPageChecker extends React.Component {
  constructor() {
    super();
    let reloading = false;

    if (window.appLoaderInitialTime) {
      const timeSincePageLoad = new Date().getTime() - window.appLoaderInitialTime;
      if (timeSincePageLoad > TIME_TO_WAIT_BEFORE_RELOAD) {
        reloading = true;
        window.location.reload();
      }
    } else {
      console.warn('login-ui app requires the window.appLoaderInitialTime variable to be set to know if it should reload the page');
    }

    this.state = {
      showChildren: !reloading,
    };
  }
  render() {
    return this.state.showChildren ? this.props.children : null;
  }
}
