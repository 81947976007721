import canopyUrls from 'canopy-urls!sofe'
import {get} from 'lodash'

/**
 * Time of Day Message
 * @returns {string} message
 */
function timeOfDayMessage() {
  const hour = new Date().getHours();
  // 12am to 12pm === morning
  if (hour < 12) {
    return 'Good Morning!';
  }
  // 12pm to 5pm === afternoon
  if (hour < 17) {
    return 'Good Afternoon!';
  }
  // 5pm to 12am === night
  return 'Good Evening!';
}

/**
 * Company Greeting
 * @returns {string} greeting
 */
function companyGreeting(company_name) {
  return `Welcome to ${company_name ? company_name : get(window, 'tenant.company_name', 'the')} client portal.`;
}

function getSubdomainPrefix() {
  switch (canopyUrls.getEnvironment()) {
    case 'integration':
      return 'integ'

    case 'staging':
      return 'stage'

    case 'development':
      return 'dev'

    default:
      return ''
  }
}

function formatPhoneNumber(num) {
  return num ?
    `(${num.slice(0, 3)})-${num.slice(3, 6)}-${num.slice(6)}` :
    '';
}

function formatLocality(tenant) {
  let locality = [];

  tenant.locality && locality.push(tenant.locality);
  tenant.region && locality.push(tenant.region);
  tenant.postal_code && locality.push(tenant.postal_code);

  return locality.join(', ');
}

function redirectInvalidUrl() {
  let newHref
  switch (canopyUrls.getEnvironment()) {
    case canopyUrls.INTEGRATION_ENVIRONMENT:
    case canopyUrls.DEVELOPMENT_ENVIRONMENT:
      newHref = "https://www.integ.clientportal.ninja"
      break;

    case canopyUrls.STAGING_ENVIRONMENT:
      newHref = "https://www.stage.clientportal.ninja"
      break;

    default:
      newHref = "https://www.clientportal.com"
      break;
  }
  window.location.href = newHref
}

export { timeOfDayMessage, companyGreeting, getSubdomainPrefix, formatPhoneNumber, formatLocality, redirectInvalidUrl };
