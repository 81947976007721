import { fetchAsObservable } from 'fetcher!sofe'

export function resetPassword (resetPasswordToken, password) {
  if (!resetPasswordToken) {
    throw new Error('need resetPasswordToken')
  }
  if (!password) {
    throw new Error('cannot reset password without a valid password')
  }

  return fetchAsObservable(`/forgot-password/${resetPasswordToken}`, {
    method: 'post',
    passThrough401: true,
    body: {
      password
    }
  })

}

export function checkResetToken (token) {
  if (!token) {
    throw new Error(`I cannot check a token that doesn't exist`)
  }

  return fetchAsObservable(`/forgot-password/${token}`, {
    passThrough401: true
  })
}
