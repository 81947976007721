import {of} from 'rxjs'
import {map} from 'rxjs/operators'
import {fetchAsObservable, fetchWithSharedCache} from 'fetcher!sofe'
import {mergeWith, isEqual, isArray, cloneDeep} from 'lodash'
import {getSubdomainPrefix} from './white-label-utils'

const defaultSettings = {
  subdomain: "",
  logo_url: "",
  color_hex: "#858585",
  login_welcome_title: "",
  login_welcome_description: "",
  login_photo_url: "",
  login_layout_id: 1,
  background_image_file: ""
}

function overrideNulls(newValue, oldValue) {
  return oldValue === null || oldValue === undefined ? newValue : oldValue
}

export function getWhiteLabelSettings() {
  return fetchAsObservable('/white-label-settings')
    .pipe(
      map(settings => mergeWith({}, defaultSettings, settings, overrideNulls))
    )
}

export function patchWhiteLabelSettings(newSettings, oldSettings = {}) {
  const patch = cloneDeep(newSettings)

  // We don't ever update these properties
  delete patch.subdomain_last_changed
  delete patch.background_image_file
  delete patch.customColorBadContrast

  // Only send up properties that have changed
  Object.keys(patch).forEach(patchedProperty => {
    if (isEqual(newSettings[patchedProperty], oldSettings[patchedProperty])) {
      delete patch[patchedProperty]
    }
  })

  if(patch.subdomain && getSubdomainPrefix().length) {
    patch.subdomain = `${patch.subdomain}.${getSubdomainPrefix()}`
  }

  const parseLink = ({ enabled, link = '', link_label = '' }) => ({
    // disable links when empty
    enabled: link?.length > 0 && enabled,
    // prepend `http://` if protocol not provided
    link: (link?.length > 0 && link?.indexOf('http') < 0) ? `http://${link}` : link,
    link_label
  }); // this semi colon is vital (due to the array on the following line)

  ['company_website_link', 'appointment_scheduler_link'].forEach(linkType => {
    if (patch[linkType]) {
      patch[linkType] = parseLink(patch[linkType])
    }
  })

  if (isArray(patch?.custom_links)) {
    patch.custom_links = patch.custom_links.map(parseLink)
  }

  if(Object.keys(patch).length) {
    return fetchAsObservable('/white-label-settings', {
      method: 'PATCH',
      body: patch,
    })
  }

  // if there were no changes here, then return an empty observable
  return of({})
}

export function postWhiteLabelLogo(rawLogoBlob) {
  const formData = new FormData()
  formData.append('files', rawLogoBlob)
  return fetchAsObservable('/white-label-logos/0/files', {
    method: 'POST',
    body: formData,
  })
}

export function postWhiteLabelBGImage(rawLogoBlob) {
  const formData = new FormData()
  formData.append('files', rawLogoBlob)
  return fetchAsObservable('/white-label-login-photos/0/files', {
    method: 'POST',
    body: formData,
  })
}

export function deleteWhiteLabelLogo() {
  return fetchAsObservable('/white-label-logos/0/files', {
    method: 'DELETE'
  })
}

export function getWithCacheWhiteLabelSettings() {
  return fetchWithSharedCache('/white-label-settings', '#/')
}

export function sendTestEmailPost(data) {
  return fetchAsObservable('/white-label-test-emails', {
    method: 'POST',
    body: data,
  })
}
