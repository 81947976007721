import React from 'react'
import styles from './mobile-wrapper.styles.css'
import Logo from '../../content-wrapper/logo/logo.component.js'
import { overlayOpacity } from '../../white-label-layouts/overlay.helper.js';
import { timeOfDayMessage, companyGreeting } from '../../white-label-settings/white-label-utils';
import { featureEnabled } from 'feature-toggles!sofe'

export default class MobileWrapper extends React.Component {

  render () {
    return (
      <Background {...this.props}>
        <div className={`${styles.contentBlock}`}>
          {this.props.children}
        </div>
      </Background>
    )
  }
}

export class LoginCard extends React.Component {
  render () {
    return (
      <div
        style={this.props.styleOverride}
        className={`cps-card ${styles.card} cps-card__height-2`}
      >
        {this.props.children}
      </div>
    )
  }
}
export class Background extends React.Component {
  render () {
    if (this.props.isWhiteLabelDomain && window.appIsMobile || this.props.preview) {
      const settings = this.props.whiteLabelSettings || {}
      const showWelcomeMessage = !!settings.show_welcome_message;
      const {
        login_photo_url: loginPhotoUrl,
        color_hex: colorHex,
        login_welcome_title: loginWelcomeTitle,
        login_welcome_description: loginWelcomeDescription,
        company_name: companyName,
      } = settings
      return (
        <div className={styles.fullPage}>
          {loginPhotoUrl && (
            <div className={styles.headerImage} style={{'backgroundImage': `url(${loginPhotoUrl})`}} >
              <div style={{
                backgroundColor: colorHex,
                opacity: overlayOpacity(showWelcomeMessage, settings),
                display: 'flex',
                width: '100%',
                height: '100%',
              }}/>
              {showWelcomeMessage && (
                <div className={styles.greetingWrapper}>
                  <div className={styles.greetingTitle}>
                    {loginWelcomeTitle || timeOfDayMessage()}
                  </div>
                  <div className={styles.greetingDescription}>
                    {loginWelcomeDescription || companyGreeting(companyName)}
                  </div>
                </div>
              )}
            </div>
          )}
          <div className={`${styles.pageContent}`}>
            {this.props.children}
          </div>
        </div>
      )
    } else {
      return (
        <div className={styles.fullPageDefault}>
          {this.props.children}
        </div>
      )
    }
  }
}

export class ContentWrapperNoHeader extends React.Component {
  render () {
    return (
      <Background>
        <LoginCard>
          <ContentWrapperOnly>
            {this.props.children}
          </ContentWrapperOnly>
        </LoginCard>
      </Background>
    )
  }
}

export class ContentWrapperOnly extends React.Component {
  render () {
    return (
      <div className={`${styles.contentWrapper}`}>
        {this.props.children}
      </div>
    )
  }
}

export class MobileDesktopWrapper extends React.Component {
  render () {
    const {mobile, image, children, whiteLabelSettings} = this.props;
    return mobile ? (
      <ContentWrapperOnly>
        <div style={{fontSize: '1.8rem', textAlign: 'center', minHeight: '30rem'}}>
          <Logo
            logoSrc={whiteLabelSettings?.logo_url}
            logoAlt={whiteLabelSettings?.company_name || ''}
          />
          <div>{children}</div>
        </div>
      </ContentWrapperOnly>
    ) : (
      <div>{children}</div>
    );
  }
}

export class HeaderAndContent extends React.Component {
  static defaultProps = {
    includeStyles: true
  }
  render () {
    return (
      <div className={`${this.props.includeStyles ? styles.contentWrapper : ''}`}>
        <div className={`${styles.welcome} cps-padding-bottom-16 ${this.getAdditionalHeaderStyles()}`}>
          {this.getHeaderContent()}
        </div>
        {this.props.children}
      </div>
    )
  }

  getAdditionalHeaderStyles = () => {
    if (this.props.bannerTextOverride || this.props.isWhiteLabelDomain) {
      return '';
    } else {
      return 'cps-text-center';
    }
  }

  getHeaderContent = () => {
    if (this.props.bannerTextOverride) {
      return this.props.bannerTextOverride;
    } else if (this.props.isWhiteLabelDomain) {
      return this.props.whiteLabelSettings?.logo_url ? (
        <Logo
          logoSrc={this.props.whiteLabelSettings?.logo_url}
          logoAlt={this.props.whiteLabelSettings?.company_name || ''}
        />
      ) : '';
    } else {
      return <Logo />;
    }
  }
}
