import { fetchAsObservable } from 'fetcher!sofe'

export function checkEmailToken (token) {
  if (!token) {
    throw new Error(`I cannot check a token that doesn't exist`)
  }

  return fetchAsObservable(`/activate-email/${token}`, {
    passThrough401: true
  })
}

export function updateEmail (emailToken) {
  if (!emailToken) {
    throw new Error('need emailToken')
  }

  return fetchAsObservable(`/activate-email/${emailToken}`, {
    method: 'post',
    passThrough401: true
  })
}
