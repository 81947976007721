import { fetchAsObservable } from 'fetcher!sofe'

export function checkActivateToken (token) {
  if (!token) {
    throw new Error(`I cannot check a token that doesn't exist`)
  }

  return fetchAsObservable(`/activate-user/${token}`, {
    passThrough401: true
  })
}

export function activateAccount (token, body = {}) {

  if (!body.first_name) {
    throw new Error(`First name is required`)
  }

  if (!body.last_name) {
    throw new Error(`Last name is required`)
  }

  if (!body.password) {
    throw new Error(`Password is required`)
  }

  return fetchAsObservable(`/activate-user/${token}`, {
    method: 'post',
    passThrough401: true,
    body
  })

}
