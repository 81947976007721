import React from 'react'
import { CpButton, CpRadio } from 'canopy-styleguide!sofe'
import { mfaTypeMap } from './code-mfa.helpers'
import { useCss, k, a, m } from 'kremling'

export function MfaType(props){
  const {
    routeToSignIn,
    mfaType,
    setMfaType,
    setTypeConfirmed,
    mfaTypes,
    mobile,
  } = props
  const scope = useCss(css)

  const handleRadioChange = (id) => {
    const newType = mfaTypes.find((type, i) => type.id === id)
    setMfaType(newType)
  }

  return (
    <div {...scope}>
      <div className='cp-mb-24'>
        A one-time passcode will be sent to you as an additional verification step.
      </div>
      <div className='cp-mb-24 cp-wt-semibold'>How do you want to get the code?</div>
      <CpRadio onChange={handleRadioChange} value={mfaType?.id} name="types">
        {mfaTypes?.map(type => (
          <>
            <CpRadio.Item key={type.id} id={type.id} className='radio-list-item cp-caption'>
              {`${mfaTypeMap[type.type]} ${type.display}`}
            </CpRadio.Item>
            {type.type === 'sms' && (
              <div className='sms-type-caption cp-caption'>
                By continuing, you agree to receive text messages. Message and data rates may apply.
              </div>
            )}
         </>
        ))}
      </CpRadio>
      <div className={a('cp-mt-40 cp-flex').m('mobile-button-group', mobile)}>
        <CpButton onClick={() => setTypeConfirmed(true)}>
          Next
        </CpButton>
        <CpButton
          btnType='secondary'
          onClick={routeToSignIn}
          className={m('cp-ml-16', !mobile)}
          >
          Back
        </CpButton>
      </div>
    </div>
  )
}


const css = k`
  .radio-list-item {
    overflow-wrap: anywhere;
    margin-bottom: 1.2rem;
  }

  .mobile-button-group {
    display: flex;
    flex-direction: column;
    height: 12.8rem;
    justify-content: space-between;
  }

  .sms-type-caption {
    margin-top: -0.4rem;
    margin-left: 2.4rem;
    color: var(--cp-color-app-secondary-text);
  }
`
