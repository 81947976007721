import React from 'react'
import { get } from 'lodash'
import cancelable from 'react-disposable-decorator'
import * as EmailValidator from 'email-validator'
import QueryString from 'query-string'
import { defer, concat } from 'rxjs'
import bowser from 'bowser'
import { a } from 'kremling'
import {track} from 'cp-analytics';
import canopyUrls from 'canopy-urls!sofe'
import Auth from 'cp-client-auth!sofe'

import styles from './login.styles.css'
import FlexibleButton from '../flexible-button/flexible-button.component.js'
import FlexibleButtonBlock from '../flexible-button-block/flexible-button-block.component.js'
import InputDivWrapper from '../input-div-wrapper/input-div-wrapper.component.js'
import Password from '../password/password.component.js'
import { login as loginApi } from './login.resource.js'
import CheckAuthCookieAndRefresh from './check-auth-cookie-and-refresh/check-auth-cookie-and-refresh.component.js'
import postLoginRedirect from './login.helpers.js'
import IconInput from '../icon-input/icon-input.component.js'
import { alreadyLoggedInAndOnboarding, onboardingRedirect } from 'src/onboarding/onboarding-navigation.helper.js'
import MobileAppDownload from '../mobile-app-download/mobile-app-download.component.js'
import RequiredField from '../form/required-field.component';

@cancelable
export default class Login extends React.PureComponent {

  constructor(props) {
    super(props)
    const env = canopyUrls.getEnvironment()
    this.shouldShowCanopyPassword =
      env === canopyUrls.STAGING_ENVIRONMENT ||
      env === canopyUrls.INTEGRATION_ENVIRONMENT ||
      env === canopyUrls.DEVELOPMENT_ENVIRONMENT;
    this.state = {
      email: this.props.emailAddress || '',
      showEmailErrors: false,
      showErrors: false,
      invalid: false,
      invalidEmail: this.props.emailAddress ? (!EmailValidator.validate(this.props.emailAddress)) : true,
      disableSignIn: false,
      showIE11Warning: bowser.name === 'Internet Explorer',
      isWhiteLabelDomain: canopyUrls.WHITELABEL_ENVIRONMENT === canopyUrls.getBrandingEnvironment(),
      parsed: QueryString.parse(this.props.location.search),
    }
  }

  componentDidMount() {
    track('practice_management', 'directory', 'login_viewed');
  }

  render () {
    const isMobileWhitelabel = this.state.isWhiteLabelDomain && this.props.mobile
    return (
      <div>
        <div className={a('cp-pb-16').t(`${styles.mobileWhiteLabelMessage} cps-body`, 'cps-title cps-wt-semibold', isMobileWhitelabel)}>
          {this.renderLoginMessage()}
        </div>
        {this.state.showIE11Warning &&
          <div className="cps-padding-bottom-16">
            Important: In the coming weeks, we will discontinue support of Internet Explorer 11. In order to have the best experience, it is recommended that you begin using an
            alternate internet browser (Edge, Chrome, Firefox, etc.) to access the system. <a href="https://support.getcanopy.com/hc/en-us/articles/360008222553-FAQ-Supported-Browsers" target="_blank" rel="noreferrer noopener">Click here</a> to learn more.
          </div>
        }
        <form onSubmit={this.submitEmailAndPassword}>
          {
            !this.props.hideEmail &&
            <InputDivWrapper
              showError={(this.state.showErrors || this.state.showEmailErrors) && this.state.invalidEmail}
            >
              <div className="cps-padding-bottom-8">
                <RequiredField>Email Address</RequiredField>
              </div>
              <IconInput
                id="canopy-login-ui-email"
                leftIcon={null}
                autoFocus
                value={this.state.email}
                type='email'
                className='cps-form-control'
                placeholder={'Enter your email address'}
                aria-label='Email'
                autoCapitalize='none'
                onChange={this.updateEmail}
                onBlur={this.emailBlur}
                tabIndex={1}
              />
              <span className={`cps-error-block ${styles.absolute}`} aria-hidden={`${!((this.state.showErrors || this.state.showEmailErrors) && this.state.invalidEmail)}`}>
                {
                  this.state.email.length === 0 ?
                  'The email field is required' :
                  'Please enter a valid email address'
                }
              </span>
              {
                this.state.invalid &&
                  <div className={`cps-red ${styles.absolute}`}>Invalid email address or password!</div>
              }
            </InputDivWrapper>
          }
          <Password
            ref={el => this.passwordEl = el}
            showErrors={this.state.showErrors}
            showCanopyPassword={this.shouldShowCanopyPassword}
            passwordLabel={<RequiredField>Password</RequiredField>}
            canopyLabel={<RequiredField>Canopy Password</RequiredField>}
          />
          <FlexibleButtonBlock>
            <FlexibleButton
              id="canopy-login-ui-login"
              btnType='primary'
              disabled={this.state.disableSignIn}
            >
              {this.props.buttonText || 'Sign in'}
            </FlexibleButton>
            {
              (!this.props.showForgotPassword && !/https:\/\/www\..*\.?clientportal\.(ninja|com)/gi.test(window.location.href)) && (
                <FlexibleButton
                  btnType='flat'
                  type='button'
                  onClick={this.routeToForgotPassword}
                >
                  Forgot your password?
                </FlexibleButton>
              )
            }
          </FlexibleButtonBlock>
          {((this.state.isWhiteLabelDomain) && <MobileAppDownload mobile={this.props.mobile} />)}
        </form>
        {!this.props.timedOut && (
          <CheckAuthCookieAndRefresh {...this.props}/>
        )}
      </div>
    )
  }

  renderLoginMessage = () => {
    if (this.props.children) {
      return this.props.children;
    } else {
      const shouldShowMessage = this.props.isWhiteLabelDomain || !this.props.mobile;
      return shouldShowMessage ? 'Please sign in' : null;
    }
  }

  routeToForgotPassword = () => {
    // works with both mobile and desktop
    const {parsed} = this.state
    const redirectUrl = parsed.redirect_url
      ? `?redirect_url=${encodeURIComponent(parsed.redirect_url)}`
      : '';

    this.props.history.push(`/login/forgot${redirectUrl}`)
  }

  emailBlur = (e) => {
    const value = e.target.value
    const invalidEmail = !EmailValidator.validate(value)
    this.setState({showEmailErrors: invalidEmail, invalidEmail })
  }

  submitEmailAndPassword = (e) => {
    e.preventDefault()
    const {invalidEmail, email, parsed} = this.state
    const invalidPassword = !this.passwordEl.getIsPasswordValid()
    if (invalidPassword || invalidEmail) {
      this.setState({showErrors: true})
    } else {
      const password = this.passwordEl.getEncodedPassword()
      const redirectUrl = parsed.redirect_url || '';
      this.nextCalled = 0
      this.setState({disableSignIn: true}, () => {
        this.props.cancelWhenUnmounted(
          concat(
            loginApi({
              redirectUrl,
              email,
              password,
              canopyPassword: this.passwordEl.getCanopyPassword(),
            }),
            defer(Auth.refetchLoggedInUser),
            defer(Auth.refetchTenant),
          ).subscribe(
            this.onNext,
            (e) => {

              if (e.status === 403) {
                const body = e.data.body
                const state = {
                  mfaTypes: body.mfa_methods,
                  uuid: body.uuid,
                  authCodeFlow: this.authCodeFlow,
                }
                const redirect = redirectUrl ? `?redirect_url=${encodeURIComponent(redirectUrl)}` : "";

                window.history.replaceState(state, '', `${this.props.mobile ? '/m' : '/#'}/login/mfa/mfa-type${redirect}` )
              } else if (e.status === 418) {
                const redirectUrl = get(e, 'data.body.redirect_url')
                if (redirectUrl) {
                  canopyUrls.navigateToUrl(redirectUrl)
                } else {
                  throw new Error(`POST /token responded with 418 to indicate user is on wrong domain, but directory service did not specify what the correct domain is`)
                }
              } else {
                this.triggerInvalid()
              }
            }
          )
        )
      });
    }
  }

  onNext = (results) => {

    if (results && results.role) {
      this.user = results
    }

    this.nextCalled = this.nextCalled + 1
    if (results && results.instance_url) {
      this.instance_url = results.instance_url
      this.authCodeFlow = this.instance_url.includes('/authorize')
    }

    if (results && results.show_multi_tenant_select && !(this.props.mobile && this.authCodeFlow)) {
      Auth.refetchLoggedInUser()
      const {parsed} = this.state
      const redirectUrl = parsed.redirect_url
        ? `?redirect_url=${encodeURIComponent(parsed.redirect_url)}`
        : '';

      this.props.history.push(`select-user${redirectUrl}`)
    }

    if (this.nextCalled === 3) {
      const matchingString = this.props.mobile ? '/m/login' : '/#/login'
      if (this.instance_url && this.instance_url.indexOf(matchingString) === -1) {
        postLoginRedirect(
          alreadyLoggedInAndOnboarding()
            ? onboardingRedirect(this.instance_url)
            : this.instance_url,
          this.user
        )
      } else {
        const rootURL = this.props.mobile && this.authCodeFlow ? '/m' : '/'
        this.props.history.push(rootURL)
      }
    }
  }

  triggerInvalid = () => {
    this.setState({invalid: true, showErrors: false, disableSignIn: false}, this.passwordEl.resetPassword)
  }

  updateEmail = (e) => {
    const newEmailValue = e.target.value
    const invalidEmail = !EmailValidator.validate(newEmailValue)
    this.setState({email: newEmailValue, invalidEmail, invalid: false})
  }

}
