import React from 'react'
import { CpButton } from 'canopy-styleguide!sofe'
import { k, useCss } from 'kremling'

export function MfaTimeout({routeToSignIn}){
  const scope = useCss(css)

  return (
    <div {...scope}>
      <div className='cp-mb-24'>
        Your authentication session timed out. You must restart the authentication process to proceed.
      </div>
      <CpButton
        className='restart-btn'
        onClick={routeToSignIn}
      >
        Restart
      </CpButton>
    </div>
  )
}

const css = k`
  .restart-btn {
    margin-top: 12.8rem;
  }
`
