import React from 'react'
import { from, pipe, EMPTY } from 'rxjs'
import { flatMap } from 'rxjs/operators'
import QueryString from 'query-string'
import { catchAsyncStacktrace } from 'auto-trace'
// project
import postLoginRedirect from '../login.helpers.js'
// sofe Imports
import Auth from 'cp-client-auth!sofe'
import Cancelable from 'react-disposable-decorator';

@Cancelable
export default class CheckAuthCookieAndRefresh extends React.PureComponent {

  componentDidMount() {
    this.timeoutId = null
    this.checkAuthCookie()
  }

  render () {
    return null
  }

  componentWillUnmount() {
    this.clearTimeout()
  }

  checkAuthCookie = () => {
    const authInfoCookie = Auth.getCookie()
    if (authInfoCookie && authInfoCookie.clientId) {
      this.props.cancelWhenUnmounted(
        from(
          Auth.checkLoginStatus()
          .catch(err => {
            // We don't want this background feature to cause toasts.
            // Log an error in the console and move on.
            console.error('Cannot check logged in status, retrying in 3 seconds')

            // The rest of the promise chain will get executed with this
            return {isLoggedIn: false};
          })
        ).pipe(
          flatMap(results => {
            if (results.isLoggedIn) {
              return Promise.all([Auth.refetchLoggedInUser(), Auth.refetchTenant()])
            } else {
              // Try again in a few seconds
              this.checkAuthCookieInFuture()
              // Don't proceed to redirect to clients
              return EMPTY
            }
          })
        ).subscribe(
          () => {
            const parsed = QueryString.parse(this.props.location.search)
            const redirectUrl = parsed.redirect_url
            postLoginRedirect(redirectUrl)
          },
          catchAsyncStacktrace()
        )
      )
    } else {
      this.checkAuthCookieInFuture()
    }
  }

  checkAuthCookieInFuture = () => {
    this.clearTimeout()

    this.timeoutId = setTimeout(() => {
      this.checkAuthCookie()
    }, 3000)
  }

  clearTimeout = () => {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId)
    }
  }
}
