import React, { useState } from 'react'
import { CpButton, CpInputField, CpCheckboxField, CpIcon } from 'canopy-styleguide!sofe'
import { useForm } from 'react-hook-form';
import { fetchAbort } from 'fetcher!sofe';
import { asyncStacktrace, catchSyncStacktrace } from 'auto-trace';
import Auth from 'cp-client-auth!sofe'
import { track } from 'cp-analytics';
import { useCss, k, a, m } from 'kremling'

export function SubmitMfa(props){
  const {
    setTypeConfirmed,
    mfaType,
    uuid,
    postLoginRedirect,
    resend,
    invalidateSession,
    mobile,
  } = props
  const { display, type } = mfaType
  const [resent, setResent] = useState(false)
  const [invalidCode, setInvalidCode] = useState('')
  const scope = useCss(css)

  const runFetch = fetchAbort();
  const { handleSubmit, control, watch } = useForm({
    defaultValues: { code: '', rememberMe: false },
    mode: 'onSubmit',
  });
  const code = watch('code')

  function submit(form) {
    setInvalidCode('')
    const body =  {
      uuid,
      code: form.code.trim(),
      remember: form.rememberMe
    }
    runFetch(`/wg/mfa-code`, {
      method: 'post',
      body,
      passThrough401: true
    }).then((response) => {
      track('practice_management', 'directory', 'login_mfa_verified')
      const showMultitenantSelect = response?.show_multi_tenant_select && !(window.appIsMobile && response?.instance_url.includes('/authorize'))
      loginComplete(showMultitenantSelect)
    }).catch(asyncStacktrace(err => {
      if (err.status === 401) {
        err.toastMessage = 'Session timed out'
        invalidateSession()
      } else if (err.status === 403) { // Code doesn't match
        setInvalidCode('Invalid code')
        err.showToast = false
      }
      catchSyncStacktrace(err)
    }));
  }

  const loginComplete = (showMultitenantSelect) => {
    Promise.all([Auth.refetchLoggedInUser(), Auth.refetchTenant()]).then(([user]) =>
      postLoginRedirect(showMultitenantSelect, user)
    )
  }

  return (
    <div {...scope}>
      <div className='cp-mb-24'>
          {`${mfaTypeMap[type]} ${display}`}.
      </div>
      <form
        noValidate
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit(submit)();
        }}
      >
        <CpInputField
          error={invalidCode}
          label='Enter the code'
          fieldName="code"
          control={control}
          type="code"
          rules={{
            minLength: {
              value: 1,
              message: 'Invalid code',
            },
          }}
        />
        <CpCheckboxField
          control={control}
          fieldName="rememberMe"
          className='cp-mt-12'
        >
          Remember this device for 30 days
        </CpCheckboxField>
        {mobile && (
          <div className='cp-mt-12'>
            <span>Didn’t get the code?{' '}</span>
            <a
              className={m('hidden', resent)}
              onClick={() => {
                resend()
                setResent(true)
              }}
            >
              Resend
            </a>
            {resent && (
              <span className={a('code-sent').m('hidden', !resent)}>
                <CpIcon name='checkmark-small'/>
                Code resent
              </span>
            )}
          </div>
        )}
        <div className={a('cp-mt-40').m('cp-flex-spread', !mobile)}>
          <div className={a('cp-flex').m('mobile-button-group', mobile)}>
            <CpButton
              type="submit"
              disabled={!code}
            >
              Next
            </CpButton>
            <CpButton
              btnType='secondary'
              onClick={() => setTypeConfirmed(false)}
              className={m('cp-ml-16', !mobile)}
            >
              Back
            </CpButton>
          </div>
          {!mobile && (
            <div className='cp-flex-column'>
              <span>Didn’t get the code?{' '}</span>
              <a
                className={m('hidden', resent)}
                onClick={() => {
                  resend()
                  setResent(true)
                }}
              >
                Resend
              </a>
              {resent && (
                <span className={a('code-sent').m('hidden', !resent)}>
                  <CpIcon name='checkmark-small'/>
                  Code resent
                </span>
              )}
            </div>
          )}
        </div>
      </form>
    </div>
  )
}

const mfaTypeMap = {
  email: 'An email with the verification code was just sent to',
  sms: 'A text message with the verification code was just sent to',
}

const css = k`
  .code-sent {
    color: var(--cp-color-app-success-text);
  }

  .hidden {
    display: none;
  }

  .mobile-button-group {
    display: flex;
    flex-direction: column;
    height: 12.8rem;
    justify-content: space-between;
  }
`
