import React from 'react'
import { isEmpty, get } from 'lodash'
import cancelable from 'react-disposable-decorator'
import { asyncStacktrace, catchSyncStacktrace } from 'auto-trace'
// Project Imports
import { MobileContext } from 'src/mobile/mobile-context.js'
import FlexibleButton from '../flexible-button/flexible-button.component.js'
import FlexibleButtonBlock from '../flexible-button-block/flexible-button-block.component.js'
import Password from '../password/password.component.js'
import { resetPassword, checkResetToken } from './reset-password.resource.js'
import { doesTokenLookValid } from '../utilities/checkToken.js'

@cancelable
export default class ResetPassword extends React.PureComponent {

  state = {
    isDisabled: true,
    invalidToken: false,
    showHelperText: true,
    badPassword: false
  }

  componentDidMount () {
    this.checkFormValidity()
    this.passwordEl.mainPassField.focus()
    const resetPasswordToken = get(this.props, 'match.params.reset_password_token')
    const softTokenCheck = doesTokenLookValid(resetPasswordToken)
    if (softTokenCheck.maybeValid) {
      this.props.cancelWhenUnmounted(
        checkResetToken(resetPasswordToken).subscribe(
          () => { this.setState({invalidToken: false})},
          () => { this.setState({invalidToken: true})}
        )
      )
    } else {
      this.setState({invalidToken: true})
    }
  }

  render () {
    return (
      <div>
        <div className='cps-subheader-sm cps-padding-bottom-16'>
          Reset Password
        </div>
        {
          this.state.invalidToken && !this.state.badPassword ?
            this.invalidToken() :
            this.validTokenForm()
        }
      </div>
    )
  }

  invalidToken = () => {
    return (
      <MobileContext.Consumer>
        {value => (
          <div>
            <span className={`cps-subheader-sm`}>
              The reset password token is expired or invalid.
              Click <a href={`${value.mobile ? '/m' : '#'}/login/forgot`}>here</a> to request a new one.
            </span>
            <FlexibleButtonBlock
              className='cps-padding-bottom-16'
              mobileClass='cps-padding-top-16'
            >
              {
                this.signInButton('primary')
              }
            </FlexibleButtonBlock>
          </div>
        )}
      </MobileContext.Consumer>
    )
  }

  validTokenForm = () => {
    return (
      <form onSubmit={this.submitPasswordReset}>
        <Password
          showConfirmPassword={true}
          showPasswordStrength={true}
          showHelperText={this.state.showHelperText}
          onInputChange={this.checkFormValidity}
          onPasswordReset={this.onPasswordReset}
          ref={el => this.passwordEl = el}
          minPasswordLength={10}
        >
          {
            this.state.badPassword &&
              <span className={`cps-red`} style={{position: 'absolute'}}>New Password doesn't meet the requirements</span>
          }
        </Password>
        <FlexibleButtonBlock>
          <FlexibleButton
            btnType='primary'
            disabled={this.state.isDisabled}
          >
            Reset
          </FlexibleButton>
          <FlexibleButton
            btnType='flat'
            type='button'
            onClick={this.routeToSignIn}
          >
            Cancel
          </FlexibleButton>
        </FlexibleButtonBlock>
      </form>
    )
  }

  checkFormValidity = () => {
    const isDisabled = !this.passwordEl.getIsPasswordAndConfirmValid()
    this.setState({isDisabled, showHelperText: true, badPassword: false})
  }

  onPasswordReset = () => {
    const isDisabled = !this.passwordEl.getIsPasswordAndConfirmValid()
    this.setState({isDisabled})
  }

  submitPasswordReset = (e) => {
    e.preventDefault()
    const password = this.passwordEl.getEncodedPassword()
    const resetPasswordToken = get(this.props, 'match.params.reset_password_token')
    this.props.cancelWhenUnmounted(
      resetPassword(resetPasswordToken, password).subscribe(
        () => {
          this.props.history.push('/login/reset-successful')
        },
        asyncStacktrace(err => {
          const status = err.status
          if (status === 401) {
            this.passwordEl.resetPassword()
            this.setState({invalidToken: true})
          } else if (status === 400) {
            // password doesn't meet requirements
            this.setState({badPassword: true, showHelperText: false}, () => {
              this.passwordEl.resetPassword()
              this.passwordEl.mainPassField.focus()
            })
          } else {
            catchSyncStacktrace(err)
          }
        })
      )
    )
  }

  signInButton = (actionType) => {
    return (
      <FlexibleButton
        btnType={`${actionType}`}
        type="button"
        onClick={this.routeToSignIn}
      >
        Return to sign in
      </FlexibleButton>
    );
  }

  routeToSignIn = () => {
    this.props.history.push('/login')
  }

}
