exports = module.exports = require("../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".src-content-wrapper-contentWrapper-styles__contentWrapper {\n  padding: 48px 50px 48px 50px;\n}\n\n.src-content-wrapper-contentWrapper-styles__welcome {\n  display: flex;\n  justify-content: center;\n  font-size: 24px;\n  color: #00BF4B;\n  color: var(--cps-color-primary);\n  font-weight: 500;\n}\n\n.src-content-wrapper-contentWrapper-styles__card {\n  width: 42rem;\n  border: 1px solid #f3f3f3 !important;\n  position: relative;\n}\n\n.src-content-wrapper-contentWrapper-styles__fullPage {\n  height: 100%;\n  width: 100%;\n  position: absolute;\n  background-color: #f4f5f8;\n  background-size: cover;\n  top: 0;\n  left: 0;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex-direction: column;\n}\n\n.src-content-wrapper-contentWrapper-styles__upgradeSuccessMsg {\n  max-width: 40rem;\n  margin-bottom: 5rem;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  text-align: center;\n}\n", ""]);

// Exports
exports.locals = {
	"contentWrapper": "src-content-wrapper-contentWrapper-styles__contentWrapper",
	"welcome": "src-content-wrapper-contentWrapper-styles__welcome",
	"card": "src-content-wrapper-contentWrapper-styles__card",
	"fullPage": "src-content-wrapper-contentWrapper-styles__fullPage",
	"upgradeSuccessMsg": "src-content-wrapper-contentWrapper-styles__upgradeSuccessMsg"
};