import React, { Component } from 'react';
import { node, object } from 'prop-types';
import { Scoped, a } from 'kremling';
import LoginGreeting from './login-greeting.component';
import { overlayOpacity }  from './overlay.helper.js';

export default class Layout3 extends Component {
  static propTypes = {
    children: node,
    settings: object,
  }

  render() {
    const { children, settings } = this.props;
    const showWelcomeMessage = settings.show_welcome_message !== undefined ? settings.show_welcome_message : true;

    return (
      <Scoped css={css}>
        <>
          <svg className="layout-angle" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="none">
            <path d="M0 0 L0 100 L100 0" fill={this.props.settings.color_hex ? this.props.settings.color_hex : `var(--cps-color-primary)`}/>
          </svg>
          <div className="layout">
            <div className="card">
              <div className={a('form').t("formWithDownload", "formWithoutDownload", !this.props.isWhiteLabelDomain)}>
                {this.props.settings.logo_url && <img src={this.props.settings.logo_url} alt={settings.company_name || "Portal Logo"} className="header-logo-img"/>}
                {children}
              </div>
              <div className="message" style={{backgroundImage: `url(${settings.login_photo_url})`}}>
                <div className="message__overlay" style={{
                  backgroundColor: settings.color_hex,
                  opacity: overlayOpacity(showWelcomeMessage, settings),
                }} />
                <div className="greeting">
                  {showWelcomeMessage &&
                    <LoginGreeting
                      loginWelcomeTitle={settings.login_welcome_title}
                      loginWelcomeDescription={settings.login_welcome_description}
                      loginCompanyName={settings.company_name}
                    />
                  }
                </div>
              </div>
            </div>
          </div>
        </>
      </Scoped>
    );
  }
}

const css = `
  & .layout {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    position: relative;
    padding-bottom: 8%;
    z-index: 2;
    max-width: 100%;
  }

  & .header-logo-img {
    min-width: 156px;
    max-width: 320px;
    min-height: 56px;
    max-height: 64px;
    object-fit: contain;
    object-position: 0 0;
    margin-bottom: 72px;
  }

  & .layout-angle {
    top: 0;
    left: 0;
    position: absolute;
    width: 65%;
    height: 65%;
    z-index: 1;
  }

  & .card {
    width: 1070px;
    min-height: 600px;
    background-color: #fff;
    border-radius: 8px;
    border: solid 1px #E9E9E9;
    overflow: hidden;
    box-shadow: 0 10px 40px -24px #393b3f;
    display: flex;
    position: relative;
    z-index: 1;
  }

  & .message {
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    width: 100%;
  }

  & .message__overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
  }

  & .greeting {
    position: relative;
    z-index: 1;
    width: 340px;
    margin: 0 auto;
    align-self: center;
  }

  & .form {
    width: 510px;
    background-color: #fff;
    flex-shrink: 0;
    align-self: center;
  }

  & .form.formWithDownload {
    padding: 90px 90px 24px 90px;
  }

  & .form.formWithoutDownload {
    padding: 90px;
  }

`;
