import React from 'react';
import { Route, Switch } from 'react-router-dom'
import { noop } from 'lodash'

import { MobileContext } from './mobile/mobile-context.js'
import CodeMfa from './MFA/code-mfa/code-mfa.component'

export default class MfaRoutes extends React.Component {

  state = {
    mfaToken: undefined,
    mfaOtherDeviceData: undefined
  }

  render() {
    const { match } = this.props
    return (
      <MobileContext.Consumer>
        {({mobile}) =>
          <Switch>
            <Route
              path={`${match.url}/mfa-type`}
              render={(props) => {
                const newProps = this.getNewProps({mobile, ...this.props})
                return <CodeMfa {...newProps} />
              }}
              exact={true}
            />
          </Switch>
        }
      </MobileContext.Consumer>
    )
  }

  getNewProps = (props = null) => {
    let newProps = {
      ...this.props,
      ...this.state,
      updateToken: this.updateToken,
      updateDeviceInfo: this.updateDeviceInfo
    }

    if(props) newProps = {...newProps, ...props}

    return newProps
  }

  updateToken = (newTokenValue, callback = noop) => {
    this.setState({mfaToken: newTokenValue}, callback)
  }

  updateDeviceInfo = (newDeviceData, callback = noop) => {
    this.setState({mfaOtherDeviceData: newDeviceData}, callback)
  }
}
