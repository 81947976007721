import { pipe, from } from 'rxjs'
import { retry, flatMap } from 'rxjs/operators'
import { fetchAsObservable } from 'fetcher!sofe'

export function logout () {
  return fetchAsObservable(`/token`, {
    method: 'DELETE'
  }).pipe(retry(2))
}

// for now this can't use fetchAsObservable because fetchAsObservable
//  does some automatic rerouting I need to avoid.
//  This will change in the future.
export function mobileLogout () {
  return from(SystemJS.import('canopy-urls!sofe')).pipe(
    flatMap(canopyUrls => from(canopyUrls.default.bootstrap().then(() => canopyUrls))),
    flatMap(
      canopyUrls => from(
        fetch(
          `${canopyUrls.default.getAuthUrl()}/wg/token`,
          {method: 'DELETE'}
        )
      ).pipe(retry(2))
    ),
  )
}
