import {fetchAsObservable} from 'fetcher!sofe'
import { pipe } from 'rxjs'
import { pluck } from 'rxjs/operators'

import {isPlainObject} from 'lodash'

export function patchUser(userId, patch) {
  if (typeof userId !== 'string') {
    throw new Error(`Cannot patch user without user id`)
  }

  if (!isPlainObject(patch)) {
    throw new Error(`Cannot patch user without a patch object`)
  }

  return fetchAsObservable(`/users/${userId}`, {
    method: 'PATCH',
    body: {users: patch},
  }).pipe(
    pluck('users')
  )
}

export function getUser(userId) {
  if (typeof userId === 'undefined') {
    throw new Error(`Cannot get user without user id`)
  }

  return fetchAsObservable(`/users/${userId}`).pipe(
    pluck('users')
  )
}
