import React from 'react'
import QueryString from 'query-string'
import { CpIcon } from 'canopy-styleguide!sofe'
import styles from './contentWrapper.styles.css'
import Logo from './logo/logo.component.js'

export default class ContentWrapper extends React.PureComponent {

  render () {
    return (
      <Background {...this.props}>
        <LoginCard>
          <HeaderAndContent
            bannerTextOverride={this.props.bannerTextOverride}
            isWhiteLabelDomain={this.props.isWhiteLabelDomain}
          >
            {this.props.children}
          </HeaderAndContent>
        </LoginCard>
      </Background>
    )
  }
}

export class LoginCard extends React.Component {
  render () {
    return (
      <div style={this.props.styleOverride} className={`cps-fixed-focus cps-card ${styles.card} cps-card__height-2`}>
        {this.props.children}
      </div>
    )
  }
}

export class Background extends React.Component {
  render() {
    const parsed = QueryString.parse(this.props.location?.search);
    return (
      <div className={`${styles.fullPage}`}>
        {parsed.upgrade_successful?.toLowerCase() === "true" && (
          <div className={`${styles.upgradeSuccessMsg}`}>
            <div className="cp-color-app-primary cps-subheader-sm cp-flex-center cp-mb-8">
              <CpIcon
                name="checkmark-circle-open-large"
                className="cp-mr-8"
              />
              Account upgrade successful!
            </div>
            <div className="cp-color-app-secondary-text">
              We've signed you out in order to complete the upgrade process.
              Sign in again for full access to your upgraded account.
            </div>
          </div>
        )}
        {this.props.children}
      </div>
    );
  }
}

export class ContentWrapperNoHeader extends React.Component {
  render () {
    return (
      <Background {...this.props}>
        <LoginCard>
          <ContentWrapperOnly>
            {this.props.children}
          </ContentWrapperOnly>
        </LoginCard>
      </Background>
    )
  }
}

export class ContentWrapperOnly extends React.Component {
  render () {
    return (
      <div className={`${styles.contentWrapper}`}>
        {this.props.children}
      </div>
    )
  }
}

export class HeaderAndContent extends React.Component {
  static defaultProps = {
    includeStyles: true
  }
  render () {
    return (
      <div className={`${this.props.includeStyles ? styles.contentWrapper : ''}`}>
        <div className={`${styles.welcome} cps-padding-bottom-32`}>
          {this.renderHeader()}
        </div>
        {this.props.children}
      </div>
    )
  }

  renderHeader = () => {
    if (this.props.bannerTextOverride) {
      return this.props.bannerTextOverride;
    } else if (this.props.isWhiteLabelDomain) {
      return null;
    } else {
      return <Logo />;
    }
  }
}
