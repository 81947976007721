import React, { Component } from 'react';
import { node, object } from 'prop-types';
import { Scoped } from 'kremling';
import LoginGreeting from './login-greeting.component';
import { overlayOpacity } from './overlay.helper.js';

export default class Layout1 extends Component {
  static propTypes = {
    children: node,
    settings: object,
  }

  render() {
    const { children, settings } = this.props;
    const showWelcomeMessage = settings.show_welcome_message !== undefined ? settings.show_welcome_message : true;

    return (
      <Scoped css={css}>
        <div className="layout">
          <div
            className="layout-img"
            style={{ backgroundImage: `url(${settings.login_photo_url})` }}
          >
            <div className="layout-img__overlay" style={{
              backgroundColor: settings.color_hex,
              opacity: overlayOpacity(showWelcomeMessage, settings),
            }}>
            </div>
            <div className="layout__greeting">
              { showWelcomeMessage &&
                <LoginGreeting
                  loginWelcomeTitle={settings.login_welcome_title}
                  loginWelcomeDescription={settings.login_welcome_description}
                  loginCompanyName={settings.company_name}
                />
              }
            </div>
          </div>
          <div className="layout-form">
            <div style={{marginBottom: '16%'}}>
              {this.props.settings.logo_url && <img src={this.props.settings.logo_url} alt={settings.company_name || "Portal Logo"} className="header-logo-img"/>}
              {children}
            </div>
          </div>
        </div>
      </Scoped>
    );
  }
}

const css = `
  & .layout {
    padding: 40px;
    background-color: #fff;
    display: flex;
    height: 92vh;
    margin-bottom: 8vh;
    width: 100%;
    max-width: 1400px;
    max-height: 1020px;
  }

  & .header-logo-img {
    min-width: 156px;
    max-width: 320px;
    min-height: 56px;
    max-height: 64px;
    object-fit: contain;
    object-position: 0 0;
    margin-bottom: 72px;
  }

  & .layout-img {
    background-color: #fff;
    flex-grow: 1;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border-radius: 7px;
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    padding: 80px 128px 80px 80px;
  }

  & .layout-img__overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: blue;
    z-index: 0;
  }

  & .layout__greeting {
    position: relative;
    z-index: 1;
    margin-bottom: 16%;
  }

  & .layout-form {
    width: 450px;
    flex-shrink: 0;
    padding: 0 70px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;
