import { useMemo, useEffect, useState} from 'react'
import { asyncStacktrace, catchSyncStacktrace } from 'auto-trace'
import canopyUrls from 'canopy-urls!sofe'
import { redirectInvalidUrl } from './white-label-utils'
import { getWhiteLabelSettings } from './white-label-settings.resource'

export function useWhiteLabelSettings() {

  const isWhiteLabelDomain = useMemo(() => {
    return canopyUrls.WHITELABEL_ENVIRONMENT === canopyUrls.getBrandingEnvironment()
  }, [])
  const [whiteLabelSettings, setWhiteLabelSettings] = useState(null)

  useEffect(() => {
    if(isWhiteLabelDomain && !whiteLabelSettings) {
      const subscription = getWhiteLabelSettings()
        .subscribe(
          settings => {
            setWhiteLabelSettings(settings)
          },
          asyncStacktrace(err => {
            if(err.status === 400) {
              // 400 means we need to redirect to a new domain. the backend should be sending a FQ domain, so let's blindly trust it
              window.location.href = err.data.body.redirect_to
            } else if(err.status === 404) {
              // 404 means that this domain has never been registered, so just go to www subdomain
              redirectInvalidUrl()
            } else {
              catchSyncStacktrace(err)
            }
          })
        )
      return () => subscription?.unsubscribe?.()
    }
  }, [isWhiteLabelDomain, whiteLabelSettings])

  return [isWhiteLabelDomain, whiteLabelSettings]
}
