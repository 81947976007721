import React from 'react';
import styles from './logo.styles.css'

const canopyLogoAlt = 'Canopy';
export const canopyLogoUrl = 'https://cdn.canopytax.com/static/login-ui/logo_black.svg';

export default function Logo({ logoSrc, logoAlt }) {

  // use white label logo
  if (logoSrc) return (
    <img
      src={logoSrc}
      alt={logoAlt}
      className={styles.whiteLabelLogo}
    />
  );

  // load canopy logo
  return (
    <img
      src={canopyLogoUrl}
      alt={canopyLogoAlt}
      height={34}
    />
  )
}
