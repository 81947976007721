import React, { Component } from 'react';
import { string } from 'prop-types';
import { Scoped } from 'kremling';

import { timeOfDayMessage, companyGreeting } from '../white-label-settings/white-label-utils';

export default class LoginGreeting extends Component {
  static propTypes = {
    loginWelcomeTitle: string,
    loginWelcomeDescription: string,
  }
  static defaultProps = {
    loginWelcomeTitle: '',
    loginWelcomeDescription: '',
  }

  render() {
    const { loginWelcomeTitle, loginWelcomeDescription, loginCompanyName } = this.props;
    return (
      <Scoped css={css}>
        <div className="greeting__title">
          {loginWelcomeTitle || timeOfDayMessage()}
        </div>
        <div className="greeting__description cps-wt-bold">
          {loginWelcomeDescription || companyGreeting(loginCompanyName)}
        </div>
      </Scoped>
    );
  }
}

const css = `
  & .greeting__title {
    color: #fff;
    font-size: 32px;
    font-weight: 700;
    margin-bottom: 10px;
  }

  & .greeting__description {
    color: #fff;
    font-size: 20px;
    white-space: pre-line;
  }
`;