import React, {lazy, Suspense} from 'react';
import { BrowserRouter, Route, Redirect, Switch } from 'react-router-dom'
import ErrorBoundary from 'canopy-react-error-boundary';
import Cancelable from 'react-disposable-decorator'
import {catchSyncStacktrace, asyncStacktrace } from 'auto-trace'
import canopyUrls from 'canopy-urls!sofe'
import { UserTenantProps } from 'cp-client-auth!sofe'

// components
import ActivateAccount from './activate-account/activate-account.component.js'
import ContentWrapper, {Background, LoginCard, HeaderAndContent, ContentWrapperNoHeader} from './content-wrapper/contentWrapper.component.js'
import LoginComponent from './login/login.component.js'
import ForgotPassword from './forgot-password/forgot-password.component.js'
import ResetPassword from './reset-password/reset-password.component.js'
import ChangeEmail from './change-email/change-email.component.js'
import LogoutComponent from './logout/logout.component.js'
import ReloadPageChecker from './utilities/reload-page-checker.component.js';
import MfaRoutes from './mfa-routes.component'
const WhiteLabelSettings = lazy(() => import(/* webpackChunkName: "white-label-settings.component" */'./white-label-settings/white-label-settings.component.js'))
const OauthComponent = lazy(() => import(/* webpackChunkName: "oauth.component" */'./oauth/oauth.component.js'))
const SelectUserComponent = lazy(() => import(/* webpackChunkName: "select-user.component" */'./select-user/select-user.component.js'))
import {CprLoader} from 'canopy-styleguide!sofe'

// whitelabel components
import Layout1 from './white-label-layouts/layout-1.component'
import Layout2 from './white-label-layouts/layout-2.component'
import Layout3 from './white-label-layouts/layout-3.component'
import OnboardingLayout from './onboarding/onboarding-layout.component'
import {getWithCacheWhiteLabelSettings} from './white-label-settings/white-label-settings.resource'
import { redirectInvalidUrl } from './white-label-settings/white-label-utils'
import {get} from 'lodash'
import { isOnboardingHash, alreadyLoggedInAndOnboarding } from 'src/onboarding/onboarding-navigation.helper.js'

const OnboardingRoutes = lazy(() => import(/* webpackChunkName: "onboarding-routes.component"*/'./onboarding/onboarding-routes.component.js'))
// helpers

@ErrorBoundary({featureName: 'login'})
@Cancelable
@UserTenantProps({
  permissions: {
    customBrandingSettings: 'company_settings_custom_branding'
  }
})
export default class Routes extends React.Component {
  state = {
    childComponentCustomBannerText: null,
    whiteLabelSettings: null,
    isWhiteLabelDomain: canopyUrls.WHITELABEL_ENVIRONMENT === canopyUrls.getBrandingEnvironment(),
    onboarding: false
  }

  componentDidMount() {
    if(this.state.isWhiteLabelDomain) {
      this.props.cancelWhenUnmounted(
        getWithCacheWhiteLabelSettings()
          .subscribe(
            settings => {
              this.setState({whiteLabelSettings: settings})
            },
            asyncStacktrace(err => {
              if(err.status === 400) {
                // 400 means we need to redirect to a new domain. the backend should be sending a FQ domain, so let's blindly trust it
                window.location.href = err.data.body.redirect_to
              } else if(err.status === 404) {
                // 404 means that this domain has never been registered, so just go to www subdomain
                redirectInvalidUrl()
              } else {
                catchSyncStacktrace(err)
              }
            })
          )
      )
    }
    const { hash } = window.location
    if (hash?.startsWith('#/oauth') && hash.includes('client_id=Zapier')) {
      window.location.assign(`${canopyUrls.getWebUrl()}/${hash}`)
    }
  }

  setOnboarding(status) {
    this.setState({
      onboarding: status
    })
  }

  render() {
    if (this.state.isWhiteLabelDomain && !this.state.whiteLabelSettings) {
      // If we render the default login layout and then switch to the custom layout,
      // there are two problems. 1) Looks weird for user to see the layout switch. 2) Causes a massive bug that took me
      // hours and hours to track down. The bug is that we end up mounting/unmounting/remounting
      // the entire component tree because we change which Layout component we are using. Since each Layout
      // component is a different class, React's reconciliation algorithm decides that it should unmount and remount
      // the entire component tree underneath the Layout whenever we change layouts. This is problematic and causes
      // bugs that result in multiple network requests. One such multiple network request bug resulted in
      // client users with MFA enabled sometimes not being able to actually login with MFA because you can only use
      // your MFA token once in an API call but we were attempting to use it twice (once per mount during componentDidMount).
      // My solution to this is to simply not render the component tree until we know for sure which layout we're working with.
      //
      // See https://canopytax.atlassian.net/browse/BLUE-560
      return null
    }

    // Backend will build an endpoint that tells us if we're onboarding or not

    const isOnboarding = this.getIsOnboarding();

    const Layout = {
      1: Layout1,
      2: Layout2,
      3: Layout3,
      4: OnboardingLayout,
      default: ContentWrapper,
    }[isOnboarding && isOnboardingHash() ? 4 : get(this.state, 'whiteLabelSettings.login_layout_id', 'default')]

    return (
      <BrowserRouter basename='/#'>
        <ReloadPageChecker>
          <Suspense fallback={<CprLoader/>}>
            <Route
              path='/login'
              render={({match, location}) => {
                let bannerTextOverride = this.state.childComponentCustomBannerText;
                return (
                  <div style={{width: '100vw', height: '100vh', backgroundColor: `${isOnboarding ? 'var(-cps-color-blue-smoke)' : 'var(--cps-color-white)'}`, display: 'flex', justifyContent:`${isOnboarding ? '' : 'center'}`, alignItems: `${isOnboarding ? '' : 'center'}`, position: 'relative'}}>
                    <Layout bannerTextOverride={bannerTextOverride} settings={this.state.whiteLabelSettings} {...this.props} match={match} location={location}>
                      <Switch>
                        <Route path={`${match.url}`} exact={true} component={LoginComponent}/>
                        <Route path={`${match.url}/forgot`} exact={true} component={ForgotPassword}/>
                        <Route path={`${match.url}/reset/:reset_password_token`} exact={true} component={ResetPassword}/>
                        <Route path={`${match.url}/change-email/:change_email_token`} exact={true} render={routeProps => <ChangeEmail {...routeProps} updateBannerText={this.updateBannerText} />} />
                        <Route path={`${match.url}/reset-successful`} exact={true} render={props => (
                          <LoginComponent {...props}>
                            Your password was reset! Please sign in.
                          </LoginComponent>
                        )}/>
                        <Route path={`${match.url}/activate-account/:activate_account_token`} render={routeProps => {
                          // Since this route could be matched independent of a immediately preceeding render,
                          // it is more accurate to call `this.getIsOnboarding()` each time, than to rely on the 'isOnboarding' variable.
                          return this.getIsOnboarding()
                            ? <OnboardingRoutes setOnboarding={this.setOnboarding.bind(this)} {...routeProps} />
                            : <ActivateAccount setOnboarding={this.setOnboarding.bind(this)} {...routeProps}/>
                        }}/>

                        <Route
                          path={`${match.url}/timed-out`}
                          exact={true}
                          render={(props) => (
                            <LoginComponent {...props} timedOut={true}>
                              Session timed out! Please sign in.
                            </LoginComponent>
                          )}
                        />
                        <Route path={`${match.url}/mfa`} render={routeProps => {
                          return (<MfaRoutes {...routeProps} />)
                        }}/>

                        {/* This route combined with the <Switch /> will cover all unknown routes under `/login` */}
                        <Route component={LogoutComponent}/>
                      </Switch>
                    </Layout>
                  </div>
                )
              }}
            />
            <Route
              path='/logout'
              render={(props) => {
                return (
                  <div style={{width: '100vw', height: '100vh'}}>
                    <ContentWrapper
                      isWhiteLabelDomain={this.state.isWhiteLabelDomain}
                      {...props}
                    >
                      <LogoutComponent {...props} />
                    </ContentWrapper>
                  </div>
                )
              }}
            />
            <Route
              path={`/oauth`}
              exact={true}
              render={(props) => {
                return (
                  <div style={{width: '100vw', height: '100vh'}}>
                    <ContentWrapperNoHeader {...props}>
                      <OauthComponent {...props} />
                    </ContentWrapperNoHeader>
                  </div>
                )
              }}
            />
            <PrivateRoute
              path="/white-label/global-settings"
              render={props => (
                <div style={{width: '100vw', height: '100vh'}}>
                  <WhiteLabelSettings {...props} />
                </div>
              )}
              loggedInUser={this.props.loggedInUser}
              permitted={this.props.permissions.customBrandingSettings}
            />
            <Route
              path='/select-user'
              exact={true}
              render={(props) => {
                return (
                  <div style={{width: '100vw', height: '100vh'}}>
                    <ContentWrapperNoHeader {...props}>
                      <SelectUserComponent {...props} />
                    </ContentWrapperNoHeader>
                  </div>
                )
              }}
            />
          </Suspense>
        </ReloadPageChecker>
      </BrowserRouter>
    )
  }

  updateBannerText = newText => {
    this.setState({childComponentCustomBannerText: newText});
  }

  getIsOnboarding = () => {
    return this.state.onboarding || alreadyLoggedInAndOnboarding();
  }
}

const PrivateRoute = ({ render, permitted, loggedInUser, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      permitted ? (
        render(props)
      ) : (
        // only redirect when a user is logged in
        !!loggedInUser && <Redirect to="/403" />
      )
    }
  />
);
