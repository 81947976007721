import { isString, replace } from 'lodash'
import { catchSyncStacktrace } from 'auto-trace'
export function doesTokenLookValid (token) {
  if (token === 'company') {
    return {
       maybeValid: true,
    }
  }

  const invalid = {
    maybeValid: false,
    email: null
  }
  if (!isString(token)) {
    return invalid
  }
  const split = token.split('.')
  if (split.length !== 3)  {
    return invalid
  }
  const encodedUserInfo = split[1]
  if (encodedUserInfo === undefined) {
    return invalid
  }
  // see note here https://www.garykessler.net/library/base64.html about url and filename safe base64. the backend is replacing + with - and / with _ so we need to put them back
  let trueBase64UserInfo = backendBase64(encodedUserInfo)
  const decodedUserInfo = window.atob(trueBase64UserInfo)
  let parsedUserInfo
  try {
    parsedUserInfo = JSON.parse(decodedUserInfo)
  } catch (e) {
    catchSyncStacktrace(e)
    return invalid
  }
  if (parsedUserInfo.email) {
    return {
      maybeValid: true,
      email: parsedUserInfo.email,
      newEmail: parsedUserInfo.newEmail,
      firstName: parsedUserInfo.firstName || '',
      lastName: parsedUserInfo.lastName || '',
      tenantId: parsedUserInfo.tenantId || ''
    }
  } else {
    return invalid
  }
}

export function backendBase64 (encoded) {
  let trueBase64UserInfo = replace(encoded, /-/g, '+')
  trueBase64UserInfo = replace(trueBase64UserInfo, /_/g, '/')
  return trueBase64UserInfo
}
