import React from 'react'
import { a, m } from 'kremling'
import styles from './flexible-button-block.styles.css'
import { MobileContext } from 'src/mobile/mobile-context.js'

function FlexibleButtonBlock (props) {
  const {
    mobile,
    className = '',
    desktopClass = '',
    mobileClass = ''
  } = props
  return (
    <div className={
      a(className)
        .m(styles.mobile, mobile)
        .m(mobileClass, mobile)
        .m(styles.desktop, !mobile)
        .m(desktopClass, !mobile)
    }>
      {props.children}
    </div>
  )
}

export default function FlexibleButtonBlockContextConsumer (props) {
  return (
    <MobileContext.Consumer>
      {value => (<FlexibleButtonBlock {...props} {...value}/>)}
    </MobileContext.Consumer>
  )
}
