import urls from 'canopy-urls!sofe';
import { hasAccess } from 'cp-client-auth!sofe'
import { get }  from "lodash"

const places = [
  '/login',
  '/login/activate-account/add-calendar',
  '/login/activate-account/company',
  '/login/activate-account/custom-branding',
  '/login/activate-account/add-team-members',
  '/login/activate-account/assign-access',
  '/login/activate-account/setup-complete',
]

const getCurrentIndex = (location, tenantPlaces) => {
  for (let i = 0; i < tenantPlaces.length; ++i) {
    if (tenantPlaces[i].includes(location.pathname)) {
      return i
    }
  }

  return null
}

export default function navigate(direction, history, location, tenant, user){

  let offset
  switch(direction) {
    case 'next':
    offset = 1
    break;
    case 'previous':
    offset = -1
    break;
    default:
    offset = 0
  }

  const current = getCurrentIndex(location, places)

  if(!current){
    history.push(places[0])
  }

  const destination = places[current + offset]

  destination && history.push(destination)

  return history
}

export function goToCanopy(){
  window.location.assign(urls.getWebUrl())
}

export function isOnboardingHash(){
  return !!~window.location.hash.indexOf('login/activate-account') && !/clientportal/.test(window.location.origin)
}

export function alreadyLoggedInAndOnboarding() {
  return (
    get(window, 'tenant.show_first_time_login') &&
    hasAccess(window.loggedInUser)(['company_settings_company_info', 'company_settings_account_management', 'company_settings_custom_branding', 'company_settings_team_members'], true)
  );
}

export function onboardingRedirect(url){
  const [end, ...rest] = url.split('').reverse()

  const instanceUrl = end === '/'
    ? rest.reverse().join('')
    : url
  return `${instanceUrl}/#/login/activate-account/company`
}
