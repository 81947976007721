import React from 'react'
import { a, m } from 'kremling'
import { CpButton } from 'canopy-styleguide!sofe'
import styles from './flexible-button.styles.css'
import { MobileContext } from 'src/mobile/mobile-context.js'
import { Link } from 'react-router-dom'

function FlexibleButton (props) {
  const {
    className,
    mobileClass,
    desktopClass,
    mobile,
    children,
    id,
    type='submit',
    ...rest
  } = props

  let modifiedClassName = getConstructedClassName({className, mobileClass, desktopClass}, mobile)

  return (
    <CpButton
      id={id || undefined}
      className={modifiedClassName}
      type={type}
      {...rest}
    >
      {children}
    </CpButton>
  )

}

export default function FlexibleButtonContextConsumer (props) {
  return (
    <MobileContext.Consumer>
      {value => (<FlexibleButton {...props} {...value}/>)}
    </MobileContext.Consumer>
  )
}

export function FlexibleLinkButton (props) {
  const {
    className,
    mobileClass,
    desktopClass,
    children,
    ...rest
  } =  props
  return (
    <MobileContext.Consumer>
      {value => (
        <Link
          className={getConstructedClassName({className, mobileClass, desktopClass}, value.mobile, true)}
          {...rest}
        >
          {children}
        </Link>
      )}
    </MobileContext.Consumer>
  )
}

function getConstructedClassName (config, mobile = false, flexCenter = false) {
  const {
    className = '',
    mobileClass = '',
    desktopClass = ''
  } = config
  return a(className)
    // scoped classes
    .m(styles.mobileButton, mobile)
    .m(styles.flexCenter, flexCenter)
    // inbound classes
    .m(mobileClass, mobile)
    .m(desktopClass, !mobile)
}
