import React, { useState, useEffect } from 'react'
import { Scoped, k } from 'kremling';
import { Modal } from 'react-disposable-modal'
import SkipSetupModalContent from 'src/onboarding/skip-setup-modal-content.component.js'
import { CpIcon, CpButton, CpCard } from 'canopy-styleguide!sofe'
import { getTenant, patchTenant } from './resources'
import {catchAsyncStacktrace} from 'auto-trace';
import { useUnloadEvent, EXITED } from 'src/onboarding/analytics-hooks/hooks.js'
import { goToCanopy } from 'src/onboarding/onboarding-navigation.helper.js'

export default function OnboardingLayout(props) {
  const [stepTitle, updateStepTitle] = useState('Personal account setup')
  const [step, updateStep] = useState(1)
  const [tenant, updateTenant] = useState(null)
  const location = props.location.pathname
  const [modalStatus, showHideModal] = useState(false)
  const [shouldExit, setExit] = useState(false)
  const [dontBotherMe, setDontBotherMe] = useState(false)

  useUnloadEvent({page_name: stepTitle}, EXITED)

  let getTenant$, patchTenant$
  useEffect(() => {
    if (step === 1 && tenant && !tenant.first_time_login_at) {
      patchTenant$ = patchTenant(tenant.id, {
          show_first_time_login: true,
          first_time_login_at: Date.now(),
        })
        .subscribe(_ => {},
          catchAsyncStacktrace()
        )
    }
    return patchTenant$ && patchTenant$.unsubscribe()
  },[tenant, step ])

  useEffect(() => {
    getTenant$ = getTenant()
      .subscribe(
        tenant => {
          updateTenant(tenant)
        },
        catchAsyncStacktrace()
      )

      return () => getTenant$ && getTenant$.unsubscribe()
  }, [step])

  useEffect(() => {
    const proceed = (shouldExit || dontBotherMe) && tenant

    if (proceed) {
      if (dontBotherMe) {
        patchTenant$ = patchTenant(tenant.id, {
          show_first_time_login: false,
          first_time_login_at: Date.now(),
        }).subscribe(_ => goToCanopy(),
          catchAsyncStacktrace()
        )
      } else {
        goToCanopy()
      }
    }

    return () => patchTenant$ && patchTenant$.unsubscribe()
    },
    [shouldExit, dontBotherMe, tenant]
 )

  useEffect(() => getCurrentStep(), [location])

  function getCurrentStep(){
    let step = 1
    let stepTitle = 'Personal Account Setup'

    if(props.location.pathname.includes('activate-account/add-calendar')) {
      step = 2
      stepTitle = 'Add Email Account and Calendar'
    }

    if(props.location.pathname.includes('activate-account/company')) {
      step = 3
      stepTitle = 'Company Setup'
    }

    if(props.location.pathname.includes('activate-account/custom-branding')) {
      step = 4
      stepTitle = 'Custom Branding'
    }

    if(props.location.pathname.includes('activate-account/add-team-members')) {
      step = 5
      stepTitle = 'Add Team Members'
    }

    if(props.location.pathname.includes('activate-account/assign-access')) {
      step = 6
      stepTitle = 'Assign Roles and Licenses'
    }

    if(props.location.pathname.includes('activate-account/setup-complete')) {
      step = 7
      stepTitle = ''
    }
    updateStep(step)
    updateStepTitle(stepTitle)
  }

  function renderChecks() {
    const checks = []
    const stick = key => <div key={key} className="stick"/>
    const liteStick = key => <div key={key} className="lite stick"/>


    for( let i = 0; i < 6; i++) {
      if (i < step) {
        checks.push(
          <div key={`${i}check`} className="filled">
            <CpIcon name="checkmark-circle-filled-small"/>
          </div>
        )
      }

      if (i >= step) {
        checks.push(
          <div key={`${i}check`}  className="empty">
            <CpIcon name="checkmark-circle-open-small"/>
          </div>
        )
      }



      if (i !== 5) {
        const stickKey = `${i}stick`
        checks.push(
          i < step -1
           ? stick(stickKey)
           : liteStick(stickKey)
        )
      }
    }

    return checks
  }

  const completed = step === 7
  const canSkip = step !== 1 && !completed

  return (
    <Scoped css={css}>
      <div className='sidebar'>
        <div className='cps-margin-left-32'>
          <div className="sidebar-text">COMPANY SETUP</div>
          <img style={{width: 210}} src='https://cdn.canopytax.com/static/login-ui/DeltaLogoCanopyWhite.svg' />
          {
            canSkip && <div className='skip-link'>
              <CpButton
                btnType='tertiary'
                className="skip-setup"
                onClick={showHideModal.bind(null, true)}
              >
                Skip setup
              </CpButton>
            </div>
          }
        </div>
      </div>
      {
        modalStatus && (
          <Modal>
            <SkipSetupModalContent
              setDontBotherMe={setDontBotherMe}
              setExit={setExit}
              cancel={showHideModal.bind(null, false)}
            />
          </Modal>
        )
      }
      {
        completed ? props.children :(
          <CpCard className="onboarding-container">
            <div className="header">
              <div className="steps-container">
                {renderChecks()}
              </div>
              <div className="step-title">{stepTitle}</div>
            </div>
            <div className="child-container">
              {props.children}
            </div>
          </CpCard>
        )
      }
    </Scoped>
  )
}

const css = k`
  .steps-container {
    display: flex;
    align-items: center;
    width: 32%;
    margin: 16px auto;
  }

  .step-title {
    font-size: 2.4rem;
    padding: 0 0 1.6rem 1.6rem;
  }

  .header {
    margin-top: 24px;
    border-bottom: 4px solid var(--cps-color-primary);
  }

  .sidebar {
    background-color: var(--cps-color-nav-bg);
    width: 36rem;
    height: 100vh;
    display: flex;
    align-items: center;
  }

  .onboarding-container {
    margin: auto;
    width: 77.2rem;
    min-height: 60rem;
    max-height: 92rem;
    height: calc(100vh - 9.2rem);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .child-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: auto;
  }

  .card-body {
    flex-grow: 1;
    overflow: auto;
  }

  .sidebar-text {
    color: var(--cp-color-nav-active-text);
    font-size: 1.8rem;
    font-weight: 600;
    margin-bottom: 1.6rem;
  }

  .stick {
    border-top: 2px solid var(--cp-color-app-icon);
    min-width: 22px;
    max-height: 0px;
    margin-left: -6px;
    margin-right: -6px;
  }

  .lite {
    border-color: var(--cp-color-nav-label);
  }

  .filled {
    color: var(--cp-color-app-icon);
  }

  .empty {
    color: var(--cp-color-nav-label);
  }

  .skip-setup {
    color: var(--cp-color-app-primary-dark-text);
    text-decoration: underline;
    padding-left: 0;
    cursor: pointer;
  }

  .footer {
    padding: 3.2rem;
    border-top: 0.1rem solid var(--cp-color-app-border);
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    display: flex;
    justify-content: space-between;
  }
`
